// import _ from 'lodash';
import { SHOW_DIALOG, HIDE_DIALOG } from './dialog.types';

const INITIAL_STATE = {
  dialogs: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_DIALOG: {
      const { id, component } = action.payload;
      return { ...state, dialogs: { ...state.dialogs, [id]: component } };
    }
    case HIDE_DIALOG: {
      const { id } = action;
      const dialogs = { ...state.dialogs };
      delete dialogs[id];
      return { ...state, dialogs };
    }
    default:
      return state;
  }
};

export default reducer;
