import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';
import cx from 'classnames';
import _ from 'lodash';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import { faCheck } from '@fortawesome/pro-duotone-svg-icons/faCheck';
import { faPen } from '@fortawesome/pro-solid-svg-icons/faPen';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';

import Avatar from 'common/components/avatar/Avatar';
import BongoFontAwesomeIcon from 'common/components/icon/BongoFontAwesomeIcon';
import CloseableDialog from 'common/components/dialog/CloseableDialog';
import { COURSE } from 'common/dattrs';
import logger from 'common/utils/logger';
import { getUserEmail } from 'redux/user/user.selectors';
import Tooltip from 'common/components/tooltip/Tooltip';
import MemberRoleSelect from 'components/course/settings/MemberRoleSelect';
import { getLabelForRole } from 'common/utils/user';

import styles from './MemberItem.module.scss';

const ACTION_ICON_SIZE = 24;

function MemberItem(props) {
  const {
    member: { userId, givenName, familyName, role },
    updateMember,
    deleteMember,
  } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [isPersisting, setIsPersisting] = useState(false);
  const [userRole, setUserRole] = useState(role);
  const [isDirty, setIsDirty] = useState(false);
  const [confirmDeleteDialogVisible, setConfirmDeleteDialogVisible] = useState(false);
  const currentUsersEmail = useSelector(getUserEmail);
  const nameVisible = Boolean(givenName || familyName);
  const roleRef = useRef('');

  useEffect(() => {
    setIsDirty(!_.isEqual(userRole, role));
  }, [userRole, role]);

  useEffect(() => {
    if (isEditing) {
      roleRef.current.focus();
    }
  }, [isEditing]);

  async function _updateMember() {
    if (role === userRole) {
      // nothing has changed, no need to make a backend call
      setIsEditing(false);
      return;
    }

    const memberInfo = { ...props.member, role: userRole };
    setIsPersisting(true);

    try {
      await updateMember(memberInfo);
    } catch (e) {
      logger.error('Error updating member information', memberInfo);
    }

    setIsPersisting(false);
    setIsEditing(false);
  }

  async function _deleteMember() {
    const memberInfo = { ...props.member };
    setIsPersisting(true);

    try {
      await deleteMember(memberInfo);
    } catch (e) {
      logger.error('Error deleting member information', memberInfo);
    }

    setIsPersisting(false);
    setIsEditing(false);
  }

  function getRoleInfo() {
    if (isEditing) {
      return (
        <MemberRoleSelect
          {...COURSE.members.member.role}
          disabled={isPersisting}
          inputRef={roleRef}
          onChange={setUserRole}
          selectedRole={userRole}
          sx={{ m: 1, minWidth: 120 }}
          size='small'
        />
      );
    }

    return <span>{getLabelForRole(role)}</span>;
  }

  function getRowActions() {
    if (isEditing) {
      return (
        <>
          {isPersisting ? (
            <Box className={styles.progressContainer}>
              <CircularProgress size={ACTION_ICON_SIZE} />
            </Box>
          ) : (
            <Tooltip title={t`Save`}>
              <span>
                <IconButton
                  {...COURSE.members.member.save}
                  aria-label={t`Save`}
                  disabled={!isDirty}
                  className={styles.saveButton}
                  onClick={_updateMember}
                >
                  <BongoFontAwesomeIcon icon={faCheck} size={ACTION_ICON_SIZE} />
                </IconButton>
              </span>
            </Tooltip>
          )}
          <Tooltip title={t`Cancel`}>
            <span>
              <IconButton
                {...COURSE.members.member.cancel}
                aria-label={t`Cancel`}
                className={styles.cancelButton}
                disabled={isPersisting}
                onClick={() => {
                  setIsEditing(false);
                  setUserRole(role);
                }}
              >
                <BongoFontAwesomeIcon icon={faXmark} size={ACTION_ICON_SIZE} />
              </IconButton>
            </span>
          </Tooltip>
        </>
      );
    }

    return (
      <>
        <Tooltip title={t`Edit`}>
          <IconButton
            {...COURSE.members.member.edit}
            className={styles.addEmailButton}
            onClick={() => {
              setIsEditing(true);
            }}
          >
            <BongoFontAwesomeIcon icon={faPen} size={ACTION_ICON_SIZE} />
          </IconButton>
        </Tooltip>
        <Tooltip title={t`Remove`}>
          <span>
            <IconButton
              {...COURSE.members.member.remove}
              aria-label={t`Remove`}
              disabled={currentUsersEmail === userId}
              className={styles.addEmailButton}
              onClick={() => {
                setConfirmDeleteDialogVisible(true);
              }}
            >
              <BongoFontAwesomeIcon icon={faTrash} size={ACTION_ICON_SIZE} />
            </IconButton>
          </span>
        </Tooltip>
      </>
    );
  }

  return (
    <div
      {...COURSE.members.memberItem.q({ email: userId, firstName: givenName, lastName: familyName, role })}
      tabIndex={0}
      role='listitem'
      className={cx(styles.memberItem, isEditing && styles.editing)}
    >
      <div className={styles.detailsContainer}>
        <Avatar
          className={styles.avatar}
          key={userId}
          size={36}
          email={userId}
          firstName={givenName}
          lastName={familyName}
        />
        <div className={styles.nameContainer}>
          {nameVisible && <span>{`${givenName} ${familyName}`}</span>}
          <span className={cx(styles.email, nameVisible && styles.nameVisible)}>{userId}</span>
        </div>
        <div className={styles.details}>
          <div className={styles.roleInfo}>{getRoleInfo()}</div>
        </div>
        <div className={cx(styles.actions, isEditing && styles.editing)}>{getRowActions()}</div>
      </div>

      {confirmDeleteDialogVisible && (
        <CloseableDialog
          {...COURSE.members.member.removeConfirmDialog}
          id='remove-user-confirm-dialog'
          title={t`Remove Member`}
          onHide={() => setConfirmDeleteDialogVisible(false)}
          footerActions={
            <>
              {isPersisting ? (
                <Box className={styles.progressContainer}>
                  <CircularProgress size={ACTION_ICON_SIZE} />
                </Box>
              ) : (
                <Button
                  {...COURSE.members.member.removeConfirmBtn}
                  variant='contained'
                  disabled={isPersisting}
                  color='error'
                  onClick={_deleteMember}
                >{t`Remove Member`}</Button>
              )}
            </>
          }
        >
          <DialogContentText>{t`Are you sure you want to remove ${userId}?`}</DialogContentText>
        </CloseableDialog>
      )}
    </div>
  );
}

MemberItem.propTypes = {
  member: PropTypes.shape({
    userId: PropTypes.string,
    givenName: PropTypes.string,
    familyName: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
  updateMember: PropTypes.func.isRequired,
  deleteMember: PropTypes.func.isRequired,
};

export default memo(MemberItem);
