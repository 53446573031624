/* eslint-disable */

export const createOrg = /* GraphQL */ `
  mutation createOrg($input: CreateOrgInput) {
    createOrg(input: $input) {
      name
      id
    }
  }
`;

export const addOrgMember = /* GraphQL */ `
  mutation AddOrgMember($input: OrgMemberInput) {
    addOrgMember(input: $input) {
      orgId
      userId
      type
    }
  }
`;

export const updateOrgMember = /* GraphQL */ `
  mutation UpdateOrgMember($input: OrgMemberInput) {
    updateOrgMember(input: $input) {
      orgId
      userId
      type
      givenName
      familyName
    }
  }
`;

export const createCourse = /* GraphQL */ `
  mutation CreateCourse($input: CourseInput) {
    createCourse(input: $input) {
      id
      name
      org
      role
      autoEnrollment {
        enabled
        start
        end
      }
    }
  }
`;

export const updateCourse = `
  mutation UpdateCourse($input: CourseUpdateInput) {
    updateCourse(input: $input) {
      id
      name
      org
      orgId
      autoEnrollment {
        enabled
        start
        end
      }
    }
  }
`;

export const deleteCourse = `
  mutation DeleteCourse($id: ID!) {
    deleteCourse(id: $id) {
      id
      name
      org
    }
  }
`;

export const addCourseMember = /* GraphQL */ `
  mutation AddCourseMember($input: CourseMemberInput) {
    addCourseMember(input: $input) {
      courseId
      userId
      role
    }
  }
`;

export const updateCourseMember = /* GraphQL */ `
  mutation UpdateCourseMember($input: CourseMemberInput) {
    updateCourseMember(input: $input) {
      courseId
      userId
      role
      givenName
      familyName
    }
  }
`;

export const deleteCourseMember = /* GraphQL */ `
  mutation DeleteCourseMember($input: CourseMemberInput) {
    deleteCourseMember(input: $input) {
      courseId
      userId
      role
    }
  }
`;

export const createActivity = /* GraphQL */ `
  mutation CreateActivity($input: ActivityInput) {
    createActivity(input: $input) {
      id
      resourceLinkId
      name
      type
      courseId
      courseName
      org
      states {
        visible
      }
    }
  }
`;

export const updateActivity = /* GraphQL */ `
  mutation UpdateActivity($input: ActivityUpdateInput) {
    updateActivity(input: $input) {
      id
      resourceLinkId
      name
      type
      courseId
      courseName
      org
      states {
        visible
      }
    }
  }
`;

export const deleteActivity = /* GraphQL */ `
  mutation DeleteActivity($id: ID!) {
    deleteActivity(id: $id)
  }
`;

export const updateUserInfo = /* GraphQL */ `
  mutation setUserInfo {
    setUserInfo {
      username
      email
      givenName
      familyName
      isSuperUser
      tocAccepted
    }
  }
`;

export const acceptTOC = /* GraphQL */ `
  mutation setUserInfo($input: UserInfoInput) {
    setUserInfo(input: $input) {
      username
      email
      givenName
      familyName
      isSuperUser
      tocAccepted
    }
  }
`;

export const updateUserSettings  = /* GraphQL */ `
  mutation updateOrgUserSettings($input: OrgUserSettingsInput!) {
    updateOrgUserSettings(input: $input) {
      notification {
        locale
        assignment {
          evaluated
          submitted
        }
      }
    }
  }
`;

export const generateJwt = /* GraphQL */ `
  mutation generateJwt($payload: String!) {
    generateJwt(payload: $payload)
  }
`;

export const enrollCurrentUserInCourse = /* GraphQL */ `
  mutation autoEnrollCaller($input: AutoEnrollInput) {
    autoEnrollCaller(input: $input) {
      courseId
      userId
      role
    }
  }
`;

export const copyActivity = /* GraphQL */ `
  mutation copyActivity($input: ActivityCopyInput) {
    copyActivity(input: $input) {
      id
      resourceLinkId
      name
      type
      courseId
      courseName
      org
      states {
        visible
      }
    }
  }
`;

export const copyCourse = /* GraphQL */ `
  mutation copyCourse($input: CourseCopyInput) {
    copyCourse(input: $input) {
      id
      name
      org
      role
      autoEnrollment {
        enabled
        start
        end
      }
      copyInfo {
        state
        sourceId
      }
    }
  }
`;
