import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';

import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';

import ActivityService from 'service/activity';
import { ACTIVITY } from 'common/dattrs';
import CloseableDialog from 'common/components/dialog/CloseableDialog';
import Loader from 'common/components/loader/Loader';
import NotificationService, { NOTIFICATION_TYPES } from 'service/notification';

function ActivityDeleteDialog(props) {
  const { activity, onHide } = props;
  const [loading, setLoading] = useState(false);
  const assignmentName = <b>{activity.name}</b>;

  async function onDelete() {
    setLoading(true);
    try {
      await ActivityService.deleteActivity({ activityId: activity.id });
      onHide();
    } catch (e) {
      NotificationService.notify({
        key: 'activity-delete-error',
        message: t`Something went wrong trying to delete this activity. Please try again.`,
        variant: NOTIFICATION_TYPES.ERROR,
        autoHideDuration: 4000,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <CloseableDialog
      id='delete-activity-dialog'
      title={t`Delete Assignment`}
      onHide={onHide}
      footerActions={
        <Button
          {...ACTIVITY.deleteDialog.delete}
          variant='contained'
          color='error'
          onClick={onDelete}
        >{t`Delete`}</Button>
      }
    >
      <>
        {loading && <Loader />}
        <DialogContentText>
          <Trans>Are you sure you want to delete {assignmentName}?</Trans>
        </DialogContentText>
      </>
    </CloseableDialog>
  );
}

ActivityDeleteDialog.propTypes = {
  activity: PropTypes.shape({ name: PropTypes.string, id: PropTypes.string }).isRequired,
  onHide: PropTypes.func.isRequired,
};

export default ActivityDeleteDialog;
