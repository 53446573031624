import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { default as AvatarMD } from '@mui/material/Avatar';
import Tooltip from 'common/components/tooltip/Tooltip';

import styles from './Avatar.module.scss';
import _ from 'lodash';

const DEFAULT_BACKGROUND = 'black';
const DEFAULT_CONSTRAST = 'white';

function getAbbreviatedTextContent({ email, firstName, lastName }) {
  if (_.isEmpty(firstName) && _.isEmpty(lastName)) {
    return email[0].toUpperCase();
  }

  let initials = '';
  if (firstName) initials += firstName[0].toUpperCase();
  if (lastName) initials += lastName[0].toUpperCase();
  return initials;
}

function getExpandedTextContent({ email, firstName, lastName }) {
  let content = email || '';
  if (!_.isEmpty(firstName) || !_.isEmpty(lastName)) {
    content = `${firstName} ${lastName}`;
  }

  return content;
}

function colorToHex(color) {
  const hex = color.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
}

function stringToColor(string) {
  if (!string) {
    return { background: DEFAULT_BACKGROUND, contrast: DEFAULT_CONSTRAST };
  }

  /* eslint-disable no-bitwise */
  let hash = 0;
  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let r = (hash >> (0 * 8)) & 0xff;
  let g = (hash >> (1 * 8)) & 0xff;
  let b = (hash >> (2 * 8)) & 0xff;
  /* eslint-enable no-bitwise */

  // https://www.w3.org/WAI/ER/WD-AERT/#color-contrast
  const yiqColor = (r * 299 + g * 587 + b * 114) / 1000;
  const contrastColor = yiqColor >= 128 ? '#000' : '#fff';
  const background = `#${colorToHex(r) + colorToHex(g) + colorToHex(b)}`;

  return { background, contrast: contrastColor };
}

function Avatar(props) {
  const { children, className, email, firstName, lastName, randomizeColor, size, tooltipEnabled } = props;
  const hasTextContent = !_.isEmpty(firstName) || !_.isEmpty(lastName) || !_.isEmpty(email);
  const expandedText = getExpandedTextContent({ email, firstName, lastName });

  function getCustomStyles() {
    let theme = {
      width: size,
      height: size,
    };

    if (randomizeColor && hasTextContent) {
      const { background, contrast } = stringToColor(expandedText);
      theme = {
        ...theme,
        bgcolor: background,
        color: contrast,
      };
    }

    return theme;
  }

  const avatarTheme = getCustomStyles();
  if (!tooltipEnabled) {
    return (
      <AvatarMD className={cx(styles.avatar, className)} sx={{ ...avatarTheme }}>
        {children || getAbbreviatedTextContent({ email, firstName, lastName })}
      </AvatarMD>
    );
  }

  return (
    <Tooltip title={expandedText} arrow>
      <AvatarMD className={cx(styles.avatar, className)} sx={{ ...avatarTheme }}>
        {children || getAbbreviatedTextContent({ email, firstName, lastName })}
      </AvatarMD>
    </Tooltip>
  );
}

Avatar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  className: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  randomizeColor: PropTypes.bool,
  size: PropTypes.number,
  tooltipEnabled: PropTypes.bool,
};

Avatar.defaultProps = {
  className: null,
  firstName: '',
  lastName: '',
  randomizeColor: true,
  size: 36,
  tooltipEnabled: false,
};

export default Avatar;
