import React from 'react';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';

import Link from '@mui/material/Link';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faRight } from '@fortawesome/pro-solid-svg-icons/faRight';
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import { faComments } from '@fortawesome/pro-solid-svg-icons/faComments';
import { ReactComponent as IV } from 'assets/images/interactive-video.svg';
import SvgIcon from '@mui/material/SvgIcon';

import { GENERAL } from 'common/dattrs';
import BongoFontAwesomeIcon from 'common/components/icon/BongoFontAwesomeIcon';
import styles from './ActivityListOverview.module.scss';

const ICON_SIZE = 36;

function AssignmentType(props) {
  const { name, icon, description } = props;
  return (
    <div {...GENERAL.activityInfo.q({ name })} role='listitem' className={styles.assignmentType}>
      <div className={styles.icon}>{icon}</div>
      <h2 className={styles.name}>{name}</h2>
      <span className={styles.description}>{description}</span>
    </div>
  );
}

AssignmentType.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
};

function ActivityListOverview(props) {
  return (
    <div className={styles.container}>
      <p className={styles.message}>{t`Here is an overview of the assignment types that you will be able to use in Bongo.`}</p>
      <Link {...GENERAL.bongoLink} className={styles.bongoLink} target='_blank' rel='noopener' href='http://bongolearn.com' underline='hover'>
        <span className={styles.linkText}>{t`Learn more`}</span>
        <BongoFontAwesomeIcon size={16} icon={faRight} />
      </Link>
      <div className={styles.assignments} role='list'>
        <AssignmentType
          name={t`Individual`}
          icon={<BongoFontAwesomeIcon size={ICON_SIZE} icon={faUser} />}
          description={t`Record or upload a video, practice presenting on a topic, or demonstrate a specific skill`}
        />
        <AssignmentType
          name={t`Question & Answer`}
          icon={<BongoFontAwesomeIcon size={ICON_SIZE} icon={faComments} />}
          description={t`A video is presented with video or text prompts and the learner is given a set amount of time to respond`}
        />
        <AssignmentType
          name={t`Group`}
          icon={<BongoFontAwesomeIcon size={ICON_SIZE} icon={faUsers} />}
          description={t`Collaborate in real-time or on your own time to create a video or other content for submission`}
        />
        <AssignmentType
          name={t`Interactive Video`}
          icon={<SvgIcon className={styles.iv} component={IV} inheritViewBox />}
          description={t`Watch a video and respond with multiple-choice or video answers at specific points during the video`}
        />
      </div>
    </div>
  );
}

export default ActivityListOverview;
