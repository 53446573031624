import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import _ from 'lodash';

import Box from '@mui/material/Box';
import BuildInfo from 'config/build-info.json';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { copyTextToClipboard } from 'common/utils/domUtils';
import logger, { LEVELS, getLoggers, getStickyLogLevels, updateStickyLogLevels } from 'common/utils/logger';

import CloseableDialog from '../../common/components/dialog/CloseableDialog';
import styles from './EnvironmentDialog.module.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`env-tabpanel-${index}`}
      aria-labelledby={`env-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `env-tab-${index}`,
    'aria-controls': `env-tabpanel-${index}`,
  };
}

function EnvironmentDialog(props) {
  const { onHide } = props;
  const [stickyLogLevels, setStickyLogLevels] = useState(getStickyLogLevels());
  const [buildInfo] = useState(BuildInfo);
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const copyText = (entries) => {
    let textString = '[Environment Info]';
    for (let i = 0; i < entries.length; i++) {
      textString += '\n' + entries[i][0] + ': ' + entries[i][1];
    }
    textString += '\n==================';

    try {
      copyTextToClipboard(textString);
    } catch (e) {
      logger.error('Something went wrong copying the environment info', e);
    }
  };

  const getEntry = (key, label, value) => {
    return (
      <div className={styles.envEntry} key={'env-info-' + key}>
        <span className={styles.envEntryLabel}>{label + ':'}</span>
        <span className={styles.envEntryValue}>{value}</span>
      </div>
    );
  };

  function getInfoTabContent() {
    // const { userId, userLocale, userTimezone } = props;
    // accessing window.top.location.href or window.parent is susceptible to cross-domain restrictions...
    let parentUrl = 'Unknown';
    try {
      parentUrl = window.location !== window.parent.location ? document.referrer : 'N/A';
    } catch (e) {
      logger.warn('Could not get Parent URL:', e);
    }

    let userAgent = 'Unknown';
    try {
      userAgent = window.navigator.userAgent;
    } catch (e) {
      logger.warn('Could not get User Agent:', e);
    }

    // let locale = 'Unknown';
    // try {
    //   locale = `${_.get(userLocale, 'frontend')} (frontend) / ${_.get(userLocale, 'backend')} (backend)`;
    // } catch (e) {
    //   logger.warn('Could not get User Locale:', e);
    // }

    let viewport = 'Unknown';
    try {
      viewport = window.innerWidth + 'x' + window.innerHeight;
    } catch (e) {
      logger.warn('Could not get Frame Viewport:', e);
    }

    let displayScale = 'Unknown';
    try {
      displayScale = window.devicePixelRatio * 100 + '%';
    } catch (e) {
      logger.warn('Could not get Display Scale:', e);
    }

    const currentTime = new Date().toString();
    const entries = [
      ['Branch', buildInfo.branch],
      ['Build Date', new Date(+buildInfo.buildDate).toLocaleString()],
      ['Build SHA', buildInfo.buildSHA],
      ['Build Number', buildInfo.buildNumber],
      // ['User ID', userId],
      ['Frame URL', window.location.href],
      ['Parent URL', parentUrl],
      ['Frame Viewport', viewport],
      ['Display Scale', displayScale],
      ['User Agent', userAgent],
      // ['User Locale', locale],
      ['User Clock', currentTime],
    ];

    return (
      <>
        <div className={styles.envEntries}>{entries.map((entry, index) => getEntry(index, entry[0], entry[1]))}</div>
        <div className={styles.envActions}>
          <Button
            variant={'outlined'}
            onClick={() => {
              copyText(entries);
            }}
          >
            {t`Copy to clipboard`}
          </Button>
        </div>
      </>
    );
  }

  const onLogLevelChange = (moduleName, newLevel) => {
    const loggers = getLoggers();
    if (loggers && loggers[moduleName]) {
      loggers[moduleName].setLevel(newLevel);
    }
    onStickyChange(moduleName, null, false); //remove sticky setting when loglevel is changed
  };

  const onStickyChange = (moduleName, level, isSticky) => {
    let _stickyLogLevels = { ...stickyLogLevels };
    if (isSticky) {
      _stickyLogLevels[moduleName] = level;
    } else {
      delete _stickyLogLevels[moduleName];
    }

    setStickyLogLevels(_stickyLogLevels);
    updateStickyLogLevels(_stickyLogLevels);
  };

  const getLoggerSelectField = (logger, index) => {
    const { name } = logger;
    const isSticky = _.has(stickyLogLevels, logger.name);
    return (
      <div className={styles.logLevel} key={'loglevel-' + index}>
        <FormControl>
          <InputLabel id='env-log-level-label'>{name}</InputLabel>
          <Select
            className={styles.levelSelect}
            labelId='env-log-level-label'
            label={name}
            onChange={(event) => {
              onLogLevelChange(name, event.target.value);
            }}
            value={logger.getLevel()}
          >
            {LEVELS.map((item) => {
              return (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={isSticky}
              onChange={(event) => {
                onStickyChange(name, logger.getLevel(), event.target.checked);
              }}
            />
          }
          label={t`Sticky`}
        />
      </div>
    );
  };

  function getModuleLoggers() {
    const modules = getLoggers();
    if (!modules) return;
    const selectFields = _.values(modules).map(getLoggerSelectField);
    return selectFields;
  }

  return (
    <CloseableDialog
      id={'environment-dialog'}
      className={styles.envDialog}
      contentClassName={styles.content}
      title={t`Environment`}
      onHide={onHide}
    >
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label='Info' {...a11yProps(0)} />
        <Tab label='Diag' {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <div className={styles.tabContent}>{getInfoTabContent()}</div>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <div className={styles.tabContent}>
          <div className={styles.logLevels}>{getModuleLoggers()}</div>
        </div>
      </TabPanel>
    </CloseableDialog>
  );
}

EnvironmentDialog.propTypes = {
  actions: PropTypes.any,
  className: PropTypes.string,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  userId: PropTypes.number,
  onHide: PropTypes.func.isRequired,
};

export default EnvironmentDialog;
