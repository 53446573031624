import { useState, useEffect } from 'react';

import { QUERY_PARAM_DIALOG } from 'common/constants/router';
import useQueryParam from 'hooks/useQueryParam';

let timeout;

export default function useRoutableDialogState() {
  const dialogName = useQueryParam(QUERY_PARAM_DIALOG);
  const [currentDialog, setCurrentDialog] = useState(dialogName);

  useEffect(() => {
    if (dialogName) {
      timeout && clearTimeout(timeout);
      setCurrentDialog(dialogName);
    } else {
      timeout = setTimeout(() => {
        setCurrentDialog(null);
      }, 300);
    }
  }, [dialogName]);

  useEffect(() => {
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, []);

  return {
    currentDialog,
  };
}
