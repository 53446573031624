import React from 'react';

import UserSettingsDialog from 'components/user/UserSettingsDialog';
import useRoutableDialogState from 'hooks/useRoutableDialogState';
import { USER_PREFS_DIALOG_KEY } from 'common/constants/router';

const dialogs = {
  [USER_PREFS_DIALOG_KEY]: UserSettingsDialog,
};

function RoutableDialogManager() {
  const { currentDialog } = useRoutableDialogState();
  const Component = dialogs[currentDialog];

  if (!Component) {
    return null;
  }

  return <Component />;
}

export default RoutableDialogManager;
