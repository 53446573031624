import { defineMessage } from '@lingui/macro';

export const SORT_ASCENDING = 'asc';
export const SORT_DESCENDING = 'desc';
export const VISIBLE = 'visible';
export const HIDDEN = 'hidden';

export const AUTO_ENROLLMENT_ACTIVE_FIELD = 'active';
export const AUTO_ENROLLMENT_INACTIVE_FIELD = 'inactive';

export const ROLE_INSTRUCTOR = 'instructor';
export const ROLE_LEARNER = 'learner';
export const ROLE_REVIEWER = 'reviewer';

export const ORG_ADMIN = 'orgadmin';
export const ORG_USER = 'orguser';

export const ROLES = [
  {
    label: defineMessage({ message: 'Learner' }),
    description: defineMessage({ message: `Submit videos for assigned work` }),
    value: ROLE_LEARNER,
  },
  {
    label: defineMessage({ message: 'Reviewer' }),
    description: defineMessage({ message: `Review learner submissions` }),
    value: ROLE_REVIEWER,
  },
  {
    label: defineMessage({ message: 'Instructor' }),
    description: defineMessage({ message: `Manage courses and review learner submissions` }),
    value: ROLE_INSTRUCTOR,
  },
];

export const SUPPORTED_LANGUAGES = [
  { label: defineMessage({ message: 'Arabic' }), value: 'ar' },
  { label: defineMessage({ message: 'German' }), value: 'de' },
  { label: defineMessage({ message: 'English' }), value: 'en' },
  { label: defineMessage({ message: 'Spanish' }), value: 'es' },
  { label: defineMessage({ message: 'French' }), value: 'fr' },
  { label: defineMessage({ message: 'French (Canada)' }), value: 'fr_CA' },
  { label: defineMessage({ message: 'Dutch' }), value: 'nl' },
  { label: defineMessage({ message: 'Portuguese (Brazil)' }), value: 'pt_BR' },
];