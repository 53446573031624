import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { faUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons/faUpRightFromSquare';

import BongoFontAwesomeIcon from 'common/components/icon/BongoFontAwesomeIcon';
import UserService from 'service/user';
import logger from 'common/utils/logger';
import logo from 'assets/images/Bongo-white.png';

import styles from './Terms.module.scss';

const TERMS_URL = 'https://www.bongolearn.com/terms/';
const PRIVACY_POLICY_URL = 'https://www.bongolearn.com/privacy-policy/';

const termsOfUseLink = (
  <Link className={styles.link} href={TERMS_URL} target='_blank' rel='noopener noreferrer' underline='always'>
    <Trans>Terms of Use</Trans>
    <BongoFontAwesomeIcon className={styles.popoutIcon} icon={faUpRightFromSquare} size={18} />
  </Link>
);

const privacyPolicyLink = (
  <Link className={styles.link} href={PRIVACY_POLICY_URL} target='_blank' rel='noopener noreferrer' underline='always'>
    <Trans>Privacy Policy</Trans>
    <BongoFontAwesomeIcon className={styles.popoutIcon} icon={faUpRightFromSquare} size={18} />
  </Link>
);

function Terms(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [acceptingTerms, setAcceptingTerms] = useState(false);
  const { from } = location.state || { from: { pathname: '/', search: '' } };

  async function acceptTerms() {
    logger.debug('Terms::acceptTerms accepting terms');
    setAcceptingTerms(true);
    await UserService.acceptTOC();
    setAcceptingTerms(false);

    const navTo = from.pathname + from.search;
    logger.debug(`Terms::acceptTerms redirecting to path: ${navTo}`);
    navigate(navTo);
  }

  return (
    <div className={styles.page}>
      <div className={styles.content}>
        <img className={styles.logo} src={logo} alt='logo' />
        <div className={styles.terms}>
          <h1>{t`Terms and Conditions`}</h1>
          <div>
            <div className={styles.infoSection}>
              <p>
                <Trans>
                  To access the Bongo application you need to accept the Terms of Use of this site. For more
                  information, please refer to the {termsOfUseLink}.
                </Trans>
              </p>
            </div>
            <div className={styles.infoSection}>
              <p>
                <Trans>
                  Bongo receives and stores Personal Information and other data that you provide to us while using our
                  products and services. For more information, please refer to the {privacyPolicyLink}.
                </Trans>
              </p>
            </div>
            <div className={styles.infoSection}>
              <p>
                <Trans>
                  Bongo uses cookies to personalize your experience and enhance our product&apos;s functionality and
                  performance by tracking which parts of our websites you have visited. For more information, please
                  refer to the {privacyPolicyLink}.
                </Trans>
              </p>
            </div>
            <div className={cx(styles.infoSection, styles.acceptSection)}>
              <p>{t`Do you accept the above Terms of Use, Privacy Policy, and Cookie Policy?`}</p>
            </div>
          </div>
          <div className={styles.actions}>
            <Button
              disabled={acceptingTerms}
              className={styles.action}
              variant='contained'
              onClick={acceptTerms}
            >{t`Accept`}</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms;
