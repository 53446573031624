import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { t } from '@lingui/macro';
import _ from 'lodash';

import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { faCircleX } from '@fortawesome/pro-solid-svg-icons/faCircleX';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons/faMagnifyingGlass';
import { styled } from '@mui/material/styles';

import BongoFontAwesomeIcon from 'common/components/icon/BongoFontAwesomeIcon';

import styles from './FilterInput.module.scss';

const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    height: 32,
    fontSize: 14,
    paddingRight: 2,
    '& fieldset': {
      borderColor: '#ccc',
    },
  },
});

const FilterInput = (props) => {
  const { className, onValueChange, placeholder, value } = props;
  return (
    <CssTextField
      className={cx(styles.filter, className)}
      fullWidth
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        onValueChange(e.target.value);
      }}
      InputProps={{
        startAdornment: <BongoFontAwesomeIcon className={styles.filterIcon} icon={faMagnifyingGlass} size={18} />,
        endAdornment: (
          <IconButton
            className={cx(styles.clearBtn, _.isEmpty(value) && styles.hidden)}
            aria-label={t`Clear filter`}
            onClick={() => {
              onValueChange('');
            }}
          >
            <BongoFontAwesomeIcon icon={faCircleX} size={18} />
          </IconButton>
        ),
      }}
    />
  );
};

FilterInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
};

export default FilterInput;
