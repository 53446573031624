import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import _ from 'lodash';
import cx from 'classnames';

import AvatarGroup from '@mui/material/AvatarGroup';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import useMediaQuery from '@mui/material/useMediaQuery';

import Avatar from 'common/components/avatar/Avatar';
import BongoFontAwesomeIcon from 'common/components/icon/BongoFontAwesomeIcon';
import styles from './CourseMemberAvatars.module.scss';

const AVATAR_MAX = 5;
const REDUCED_AVATAR_MAX = 3;

function CourseMemberAvatars(props) {
  const { courseMembers } = props;
  const showReducedAvatarGroup = useMediaQuery('(max-width:500px)');

  if (_.isEmpty(courseMembers)) {
    return (
      <div className={styles.emptyMembers}>
        <Avatar size={26} randomizeColor={false}>
          <BongoFontAwesomeIcon className={styles.calIcon} icon={faUser} size={16} />
        </Avatar>
        <span className={styles.message}>{t`No Members`}</span>
      </div>
    );
  }

  const max = showReducedAvatarGroup ? REDUCED_AVATAR_MAX : AVATAR_MAX;
  let overflowCount = 0;
  if (courseMembers.length > max) {
    overflowCount = courseMembers.length - max;
  }

  let overflowStyle = null;
  if (overflowCount > 10 && overflowCount < 100) {
    overflowStyle = styles.doubleDigit;
  } else if (overflowCount >= 100) {
    overflowStyle = styles.tripleDigit;
  }

  return (
    <div className={styles.avatars}>
      <AvatarGroup
        sx={{ '& .MuiAvatar-root': { width: 32, height: 32, fontSize: '1em' } }}
        max={max}
        total={Math.min(max, courseMembers.length)}
      >
        {courseMembers.map((member) => (
          <Avatar
            key={member.userId}
            size={32}
            email={member.userId}
            firstName={member.givenName}
            lastName={member.familyName}
            tooltipEnabled={true}
          />
        ))}
      </AvatarGroup>
      {overflowCount > 0 && (
        <div
          aria-label={t`plus ${overflowCount} members`}
          className={cx(styles.avatarOverflow, overflowStyle)}
        >{`+${+overflowCount}`}</div>
      )}
    </div>
  );
}

CourseMemberAvatars.propTypes = {
  courseMembers: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string.isRequired,
      givenName: PropTypes.string,
      familyName: PropTypes.string,
    })
  ),
};

CourseMemberAvatars.defaultProps = {
  courseMembers: [],
};

export default CourseMemberAvatars;
