import React from 'react';
import { t } from '@lingui/macro';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import Permissions from 'common/utils/permissions';
import SortField from 'common/components/input/SortField';
import { getCourseSortField, getCourseSortDirection, getRawCourses } from 'redux/course/course.selectors';
import { setCourseSortField, setCourseSortDirection } from 'redux/course/course.actions';
import { SORT_ASCENDING, SORT_DESCENDING } from 'common/constants/general';

const CourseListSortField = (props) => {
  const sortField = useSelector(getCourseSortField);
  const sortDirection = useSelector(getCourseSortDirection);
  const dispatch = useDispatch();
  const rawCourses = useSelector(getRawCourses);
  const isCourseAutoEnrollmentStatusVisible = Permissions.isCourseAutoEnrollmentStatusVisibleForAnyCourse(rawCourses);

  const items = [
    { label: t`Name`, value: 'name' },
    { label: t`Auto Enrollment`, value: 'autoEnrollment', visible: isCourseAutoEnrollmentStatusVisible },
    { label: t`Role`, value: 'role' },
  ];

  return (
    <SortField
      items={items}
      value={sortField}
      sortDirection={sortDirection}
      onSortDirectionToggle={() =>
        dispatch(setCourseSortDirection(sortDirection === SORT_ASCENDING ? SORT_DESCENDING : SORT_ASCENDING))
      }
      onSortFieldChange={(value) => dispatch(setCourseSortField(value))}
    />
  );
};

export default CourseListSortField;
