import React from 'react';
import { i18n } from '@lingui/core';
import { defineMessage, t } from '@lingui/macro';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import _ from 'lodash';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import useMediaQuery from '@mui/material/useMediaQuery';

import FilterField from 'common/components/input/FilterField';
import Permissions from 'common/utils/permissions';
import { INDIVIDUAL, GROUP, QA, INTERACTIVE_VIDEO } from 'common/constants/activityTypes';
import { VISIBLE, HIDDEN } from 'common/constants/general';
import { getActivityFilters, getActivityFilterFieldValue } from 'redux/activity/activity.selectors';
import { updateActivityFilters, setActivityFilterFieldValue } from 'redux/activity/activity.actions';
import { getActiveCourse } from 'redux/course/course.selectors';

import ActivityListFilterInput from './ActivityListFilterInput';
import styles from './ActivityListFilterField.module.scss';

const ACTIVITY_FILTERS = [
  {
    label: defineMessage({ message: 'Individual' }),
    value: INDIVIDUAL,
  },
  {
    label: defineMessage({ message: 'Interactive Video' }),
    value: INTERACTIVE_VIDEO,
  },
  {
    label: defineMessage({ message: 'Group' }),
    value: GROUP,
  },
  {
    label: defineMessage({ message: 'Question & Answer' }),
    value: QA,
  },
];

const VISIBILITY_FILTERS = [
  {
    label: defineMessage({ message: 'Visible' }),
    value: VISIBLE,
  },
  {
    label: defineMessage({ message: 'Hidden' }),
    value: HIDDEN,
  },
];

const ActivityListFilterField = () => {
  const filters = useSelector(getActivityFilters);
  const filterValue = useSelector(getActivityFilterFieldValue);
  const dispatch = useDispatch();
  const mediumWidth = useMediaQuery('(min-width:700px)');
  const course = useSelector(getActiveCourse);

  function isFilterChecked(section, name) {
    return _.get(filters, section, []).includes(name);
  }

  function onTypeFilterChanged(name, event) {
    const { type } = filters;
    let types = [...type];

    if (event.target.checked) {
      types.push(name);
    } else {
      _.remove(types, function (item) {
        return item === name;
      });
    }

    dispatch(updateActivityFilters({ type: _.uniq(types), visibility: [...filters.visibility] }));
  }

  function onVisibilityFilterChanged(name, event) {
    const { visibility } = filters;
    let visibleFilters = [...visibility];

    if (event.target.checked) {
      visibleFilters.push(name);
    } else {
      _.remove(visibleFilters, function (item) {
        return item === name;
      });
    }

    dispatch(updateActivityFilters({ type: [...filters.type], visibility: _.uniq(visibleFilters) }));
  }

  function getFilterCount() {
    let count = filters.type.length + filters.visibility.length;
    if (!mediumWidth && !_.isEmpty(filterValue)) {
      count++;
    }
    return count;
  }

  function onClearFilters() {
    dispatch(updateActivityFilters({ type: [], visibility: [] }));
    if (!mediumWidth) {
      dispatch(setActivityFilterFieldValue(''));
    }
  }

  function createFilterItem(filterItem, field, callback) {
    const { label, value } = filterItem;
    return (
      <FormControlLabel
        key={`activityFilterItem[${field}][${value}]`}
        control={
          <Checkbox
            checked={isFilterChecked(field, value)}
            onChange={(e) => {
              callback(value, e);
            }}
            name={value}
          />
        }
        label={i18n._(label)}
      />
    );
  }

  function createTypeFilterItem(filterItem) {
    return createFilterItem(filterItem, 'type', onTypeFilterChanged);
  }

  function createVisibilityFilterItem(filterItem) {
    return createFilterItem(filterItem, 'visibility', onVisibilityFilterChanged);
  }

  return (
    <FilterField filterCount={getFilterCount()}>
      <div className={styles.filterContainer}>
        <div className={styles.header}>
          <h3 className={styles.title}>{t`Filters`}</h3>
          <Button
            className={styles.clearButton}
            variant='outlined'
            color='primary'
            size='small'
            disabled={!getFilterCount()}
            aria-label={t`clear filter options`}
            onClick={onClearFilters}
          >
            {t`Clear`}
          </Button>
        </div>
        <Divider className={styles.headerDivider} />
        <section>{!mediumWidth && <ActivityListFilterInput className={styles.filterInput} />}</section>
        <section className={cx(styles.filterSection, styles.typeOptions)}>
          <h4>{t`Type`}</h4>
          <FormControl className={styles.filterSectionContent}>
            <FormGroup>{ACTIVITY_FILTERS.map((item) => createTypeFilterItem(item))}</FormGroup>
          </FormControl>
        </section>

        {Permissions.isActivityVisibilityVisible(course?.role) && (
          <section className={cx(styles.filterSection, styles.visibilityOptions)}>
            <h4>{t`Visibility`}</h4>
            <FormControl className={styles.filterSectionContent}>
              <FormGroup>{VISIBILITY_FILTERS.map((item) => createVisibilityFilterItem(item))}</FormGroup>
            </FormControl>
          </section>
        )}
      </div>
    </FilterField>
  );
};

export default ActivityListFilterField;
