import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { generatePath } from 'react-router';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';
import _ from 'lodash';

import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft';

import { ACTIVITY } from 'common/dattrs';
import ActivityService from 'service/activity';
import AppBar from 'components/appbar/AppBar';
import BongoFontAwesomeIcon from 'common/components/icon/BongoFontAwesomeIcon';
import BongoLtiLaunch from 'launch/BongoLtiLaunch';
import InlineMessage from 'components/InlineMessage';
import Loader from 'common/components/loader/Loader';
import { isSuperUser } from 'redux/user/user.selectors';

import styles from './Activity.module.scss';

function Activity() {
  const { activityId, courseId, institutionId } = useParams();
  const [loading, setLoading] = useState(false);
  const [activity, setActivity] = useState(null);
  const [hasFetchError, setHasFetchError] = useState(false);
  const _isSuperUser = useSelector(isSuperUser);
  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  useEffect(() => {
    async function fetchActivity() {
      setLoading(true);
      try {
        setActivity(await ActivityService.fetchActivity(activityId));
      } catch (e) {
        setHasFetchError(true);
      }
      setLoading(false);
    }

    fetchActivity();
  }, [activityId]);

  function routeToAssignments() {
    const _institutionId = institutionId || _.get(activity, 'orgId');
    if (_isSuperUser) {
      navigate(
        generatePath(`/institutions/:institutionId/courses/:courseId`, {
          courseId,
          institutionId: _institutionId,
        })
      );
    } else {
      navigate(
        generatePath(`/courses/:courseId`, {
          courseId,
        })
      );
    }
  }

  return (
    <div className={styles.container}>
      <Loader visible={loading} />
      <AppBar
        className={styles.appBar}
        leftActions={[
          <div className={styles.actionsContainer} key={'leftActions'}>
            <div>
              <ListItemButton
                {...ACTIVITY.backToActivities}
                key='back-btn'
                aria-label={t`Back to assignments`}
                onClick={routeToAssignments}
                className={styles.backButton}
              >
                <BongoFontAwesomeIcon icon={faArrowLeft} size={24} />
                <Typography className={styles.username}>{t`Assignments`}</Typography>
              </ListItemButton>
            </div>
          </div>,
        ]}
      />
      {activity && <BongoLtiLaunch courseId={courseId} resourceLinkId={activity.resourceLinkId} role={locationState?.role} />}
      {hasFetchError && (
        <InlineMessage
          className={styles.errorMessage}
          title={t`Error fetching assignment`}
          message={t`Something went wrong getting information for this assignment`}
        />
      )}
    </div>
  );
}

export default Activity;
