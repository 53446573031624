import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';

import useMediaQuery from '@mui/material/useMediaQuery';

import CloseableDialog from 'common/components/dialog/CloseableDialog';
import InstitutionAdmin from './InstitutionAdmin';
import styles from './InstitutionSettingsDialog.module.scss';

function InstitutionSettingsDialog(props) {
  const { onHide } = props;
  const fullScreen = useMediaQuery('(max-width:480px)');

  return (
    <CloseableDialog id='institution-settings-dialog' fullScreen={fullScreen} title={t`Settings`} onHide={onHide}>
      <div className={styles.content}>
        <div className={styles.leftContent}></div>
        <div className={styles.rightContent}>
          <div className={styles.adminsSection}>
            <InstitutionAdmin />
          </div>
        </div>
      </div>
    </CloseableDialog>
  );
}

InstitutionSettingsDialog.propTypes = {
  onHide: PropTypes.func.isRequired,
};

export default InstitutionSettingsDialog;
