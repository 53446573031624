import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';
import _ from 'lodash';

import ListItemButton from '@mui/material/ListItemButton';
import { faCirclePlus } from '@fortawesome/pro-duotone-svg-icons/faCirclePlus';

import { ACTIVITY } from 'common/dattrs';
import ActivityService from 'service/activity';
import ActivitySettingsDialog from 'components/activity/ActivitySettingsDialog';
import BongoFontAwesomeIcon from 'common/components/icon/BongoFontAwesomeIcon';
import CreateActivityDialog from 'components/activity/CreateActivityDialog';
import Permissions from 'common/utils/permissions';
import Section from 'common/components/layout/Section';
import { getActiveCourse } from 'redux/course/course.selectors';
import { getRawActivities } from 'redux/activity/activity.selectors';
import { COPY_STATES } from 'common/constants/copyStates';

import ActivityListOptions from './ActivityListOptions';
import ActivityListItem from './ActivityListItem';
import styles from './ActivityList.module.scss';

function ActivityList(props) {
  const { activities: _activities, courseId } = props;
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [bongoDialogVisible, setBongoDialogVisible] = useState(false);
  const [createActivityType, setCreateActivityType] = useState(null);
  const [rawActivitiesCount, setRawActivitiesCount] = useState(0);
  const [activities, setActivities] = useState([]);
  const rawActivities = useSelector(getRawActivities);
  const course = useSelector(getActiveCourse);

  async function fetchData() {
    if (courseId) {
      await ActivityService.fetchActivities(courseId);
    }
  }

  useLayoutEffect(() => {
    const items = [];
    for (let i = 0; i < _activities.length; i++) {
      const activity = _activities[i];
      const state = _.get(activity, 'copyInfo.state');
      if (Permissions.isActivityCopyVisible(course?.role) || (!state || state === COPY_STATES.SUCCESS)) {
        items.push(activity);
      }
    }

    setActivities(items);
  }, [_activities]); //eslint-disable-line

  // use raw activities to get the count since this view might be filtered based on the users permissions
  useLayoutEffect(() => {
    let count = 0;
    for (let i = 0; i < rawActivities.length; i++) {
      const activity = rawActivities[i];
      const state = _.get(activity, 'copyInfo.state');
      if (Permissions.isActivityCopyVisible(course?.role) || (!state || state === COPY_STATES.SUCCESS)) {
        count++;
      }
    }

    setRawActivitiesCount(count);
  }, [rawActivities]); //eslint-disable-line

  const filteredActivityCount = activities.length;
  const totalActivityCount = rawActivitiesCount;
  const secondaryTitle =
    rawActivitiesCount !== activities.length
      ? t`(showing ${filteredActivityCount} of ${totalActivityCount})`
      : `(${activities.length})`;

  return (
    <Section title={t`Assignments`} secondaryTitle={secondaryTitle} className={styles.container}>
      <div className={styles.activityListContainer}>
        <div className={styles.filterContainer}>
          <ActivityListOptions />
        </div>
        {Permissions.isActivityCreationVisible(course?.role) ? (
          <ListItemButton
            {...ACTIVITY.list.item.create}
            aria-label={t`Create Assignment`}
            component='a'
            onClick={() => {
              setCreateDialogVisible(true);
            }}
            className={styles.createButton}
          >
            <BongoFontAwesomeIcon
              className={styles.addIcon}
              icon={faCirclePlus}
              size={28}
              style={{ '--fa-primary-color': '#000', '--fa-secondary-color': '#888' }}
            />
            <span className={styles.name}>{t`Create Assignment`}</span>
          </ListItemButton>
        ) : (
          <div className={styles.separator}></div>
        )}
        <div
          {...ACTIVITY.list.items.q({ count: activities.length })}
          role='list'
          aria-label={t`Assignments`}
          className={styles.activityList}
        >
          {rawActivitiesCount !== activities.length && activities.length === 0 && (
            <div className={styles.emptyList}>
              <span>{t`No assignments found`}</span>
            </div>
          )}
          {_.map(activities, (activity) => {
            return (
              <ActivityListItem
                key={`activity_${activity.id}`}
                className={styles.listItem}
                courseRole={course?.role}
                activity={activity}
              />
            );
          })}
        </div>
      </div>

      {createDialogVisible && (
        <CreateActivityDialog
          onCreate={(type) => {
            setCreateDialogVisible(false);
            setCreateActivityType(type);
            setBongoDialogVisible(true);
          }}
          onHide={() => {
            setCreateDialogVisible(false);
          }}
        />
      )}

      {bongoDialogVisible && (
        <ActivitySettingsDialog
          courseId={courseId}
          type={createActivityType}
          onHide={() => {
            setBongoDialogVisible(false);
            fetchData();
          }}
        />
      )}
    </Section>
  );
}

ActivityList.propTypes = {
  activities: PropTypes.array,
  courseId: PropTypes.string.isRequired,
};

ActivityList.defaultProps = {
  activities: [],
};

export default ActivityList;
