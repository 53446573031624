import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import _ from 'lodash';

import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { faGear } from '@fortawesome/pro-solid-svg-icons/faGear';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons/faEllipsisVertical';
import { faLinkSimple } from '@fortawesome/pro-solid-svg-icons/faLinkSimple';
import { faDownload } from '@fortawesome/pro-solid-svg-icons/faDownload';
import { faCopy } from '@fortawesome/pro-regular-svg-icons/faCopy';
import useMediaQuery from '@mui/material/useMediaQuery';

import BongoFontAwesomeIcon from 'common/components/icon/BongoFontAwesomeIcon';
import CourseService from 'service/course';
import CourseSettingsDialog from 'components/course/settings/CourseSettingsDialog';
import NotificationService from 'service/notification';
import Permissions from 'common/utils/permissions';
import Tooltip from 'common/components/tooltip/Tooltip';
import { COURSE } from 'common/dattrs';

import styles from './CourseActions.module.scss';

export default function CourseActions(props) {
  const { courseId, onCopyCourse, onCourseLinkCopy, role } = props;
  const showActionsInline = useMediaQuery('(min-width:600px)');
  const [courseSettingsVisible, setCourseSettingsVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [generatingLink, setGeneratingLink] = useState(false);
  const [downloadingSCORM, setDownloadingSCORM] = useState(false);

  function handleMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  async function copyCourseLink() {
    setGeneratingLink(true);
    await onCourseLinkCopy();
    setGeneratingLink(false);
    handleMenuClose();
  }

  async function onDownloadSCORM() {
    setDownloadingSCORM(true);
    const result = await CourseService.generateLink({ courseId });
    const scorm = await CourseService.downloadSCORMPackage({ courseId, url: result });
    window.open(scorm, '_self');
    NotificationService.notify({ message: t`SCORM package download started` });
    setDownloadingSCORM(false);
    handleMenuClose();
  }

  function getOverflowMenu() {
    const groupOneActions = [];
    const groupTwoActions = [];
    const groupThreeActions = [];

    if (Permissions.isCourseCopyVisible(role)) {
      groupOneActions.push(
        <MenuItem
          key={`courseCopyMenuItem[${courseId}]`}
          sx={{ padding: 1.5 }}
          onClick={() => {
            onCopyCourse(true);
            handleMenuClose();
          }}
        >
          <ListItemIcon>{<BongoFontAwesomeIcon icon={faCopy} size={21} />}</ListItemIcon>
          <ListItemText>{t`Make a copy`}</ListItemText>
        </MenuItem>
      );
    }

    if (Permissions.isCourseCopyLinkVisible(role)) {
      groupTwoActions.push(
        <MenuItem sx={{ padding: 1.5 }} key={`coureLinkCopyMenuItem[${courseId}]`} disabled={generatingLink} onClick={copyCourseLink}>
          <ListItemIcon>
            {generatingLink ? <CircularProgress size={24} /> : <BongoFontAwesomeIcon icon={faLinkSimple} size={21} />}
          </ListItemIcon>
          <ListItemText>{t`Copy course link`}</ListItemText>
        </MenuItem>
      );
    }

    if (Permissions.isCourseDownloadSCORMVisible(role)) {
      groupTwoActions.push(
        <MenuItem sx={{ padding: 1.5 }} key={`courseDownloadSCORMMenuItem[${courseId}]`} disabled={downloadingSCORM} onClick={onDownloadSCORM}>
          <ListItemIcon>
            {downloadingSCORM ? <CircularProgress size={24} /> : <BongoFontAwesomeIcon icon={faDownload} size={21} />}
          </ListItemIcon>
          <ListItemText>{t`Download SCORM package`}</ListItemText>
        </MenuItem>
      );
    }

    if (Permissions.isCourseSettingsVisible(role) && !showActionsInline) {
      groupThreeActions.push(
        <MenuItem
          key={`courseSettingsMenuItem[${courseId}]`}
          sx={{ padding: 1.5 }}
          onClick={() => {
            setCourseSettingsVisible(true);
            handleMenuClose();
          }}
        >
          <ListItemIcon>
            <BongoFontAwesomeIcon icon={faGear} size={21} />
          </ListItemIcon>
          <ListItemText>{t`Settings`}</ListItemText>
        </MenuItem>
      );
    }

    if (groupOneActions.length && (groupTwoActions.length || groupThreeActions.length)) {
      groupOneActions.push(<Divider key={`courseGroupOneDivider[${courseId}]`} />);
    }

    if (groupTwoActions.length && groupThreeActions.length) {
      groupTwoActions.push(<Divider key={`courseGroupTwoDivider[${courseId}]`} />);
    }

    const menuActions = [...groupOneActions, ...groupTwoActions, ...groupThreeActions];
    if (_.isEmpty(menuActions)) {
      return null;
    }

    return (
      <>
        <Tooltip aria-label={t`Course options`} title={t`Options`}>
          <IconButton
            {...COURSE.options}
            id={`course-options`}
            aria-controls={anchorEl ? `course-options-menu` : undefined}
            aria-haspopup='true'
            aria-expanded={anchorEl ? 'true' : undefined}
            onClick={handleMenuOpen}
          >
            <BongoFontAwesomeIcon size={24} icon={faEllipsisVertical} />
          </IconButton>
        </Tooltip>
        <Menu
          id='course-options-menu'
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          MenuListProps={{
            'aria-labelledby': `course-options`,
          }}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {[...menuActions]}
        </Menu>
      </>
    );
  }

  return (
    <div className={styles.actions}>
      {showActionsInline && Permissions.isCourseSettingsVisible(role) && (
        <Tooltip title={t`Settings`}>
          <IconButton
            {...COURSE.settingsItem}
            onClick={() => {
              setCourseSettingsVisible(true);
            }}
          >
            <BongoFontAwesomeIcon size={24} icon={faGear} />
          </IconButton>
        </Tooltip>
      )}

      {getOverflowMenu()}

      {courseSettingsVisible && (
        <CourseSettingsDialog
          courseId={courseId}
          onHide={() => {
            setCourseSettingsVisible(false);
          }}
        />
      )}
    </div>
  );
}

CourseActions.propTypes = {
  courseId: PropTypes.string.isRequired,
  onCopyCourse: PropTypes.func.isRequired,
  onCourseLinkCopy: PropTypes.func.isRequired,
  role: PropTypes.string,
};
