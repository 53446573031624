import React from 'react';
import { ulid } from 'ulid';
import DialogService from 'service/dialog';

export default function useDialog(component, props = {}) {
  if (!component) {
    return null;
  }

  const id = ulid();
  const extendedProps = { dialogId: id, ...props };
  const componentConfig = { id, component: React.createElement(component, { ...extendedProps }) };

  const show = () => {
    DialogService.showDialog(componentConfig);
  };

  const hide = () => {
    DialogService.hideDialog(id);
  };

  return { ...componentConfig, show, hide };
}
