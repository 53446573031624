import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import _ from 'lodash';

import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';

import { COURSE } from 'common/dattrs';
import CloseableDialog from 'common/components/dialog/CloseableDialog';
import DialogService from 'service/dialog';

const MAX_COURSE_NAME_LENGTH = 200;

function CourseAddDialog(props) {
  const { dialogId } = props;
  const [courseName, setCourseName] = useState('');
  const buttonDisabled = _.isEmpty(_.trim(courseName));

  function onCreateCourse(event) {
    props.onCreate(_.trim(courseName));
    setCourseName('');
  }

  function onClose() {
    DialogService.hideDialog(dialogId);
  }

  return (
    <CloseableDialog
      id='create-course-dialog'
      onHide={onClose}
      title={t`Create Course`}
      footerActions={
        <Button {...COURSE.createDialog.create} variant='contained' disabled={buttonDisabled} onClick={onCreateCourse}>
          {t`Create`}
        </Button>
      }
    >
      <DialogContentText>{t`Enter the name of the course you want to create.`}</DialogContentText>
      <TextField
        id='create-course-name-field'
        {...COURSE.createDialog.input}
        name='course-name'
        autoFocus
        fullWidth
        required
        onChange={(event) => setCourseName(event.currentTarget.value)}
        maxLength={MAX_COURSE_NAME_LENGTH}
        variant='standard'
      />
    </CloseableDialog>
  );
}

CourseAddDialog.propTypes = {
  dialogId: PropTypes.string.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default CourseAddDialog;
