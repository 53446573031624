import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import cx from 'classnames';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import Slide from '@mui/material/Slide';

import { DIALOG, dattrsFromProps } from 'common/dattrs';
import BongoFontAwesomeIcon from 'common/components/icon/BongoFontAwesomeIcon';

import styles from './CloseableDialog.module.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const BACKDROP_CLICK = 'backdropClick';
function CloseableDialog(props) {
  const {
    children,
    className,
    contentClassName,
    closeOnBackdropClick,
    description,
    footerActions,
    fullScreen,
    fullWidth,
    id,
    maxWidth,
    onHide,
    title,
  } = props;

  return (
    <Dialog
      {...dattrsFromProps(props)}
      id={id}
      className={cx(styles.dialog, className)}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={true}
      onClose={(e, reason) => {
        if (!closeOnBackdropClick && reason === BACKDROP_CLICK) {
          e.preventDefault();
          return;
        }
        onHide();
      }}
      TransitionComponent={Transition}
    >
      <DialogTitle {...DIALOG.title} className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          {fullScreen ? <h1 className={styles.title}>{title}</h1> : <h2 className={styles.title}>{title}</h2>}
          <p className={styles.description}>{description}</p>
        </div>
        <div className={styles.buttonContainer}>
          <IconButton {...DIALOG.close} className={styles.closeButton} aria-label={t`Close`} onClick={onHide}>
            <BongoFontAwesomeIcon size={fullScreen ? 28 : 21} icon={faTimes} />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent className={contentClassName}>{children}</DialogContent>
      <DialogActions className={styles.actions}>{footerActions}</DialogActions>
    </Dialog>
  );
}

CloseableDialog.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  className: PropTypes.string,
  closeOnBackdropClick: PropTypes.bool,
  contentClassName: PropTypes.string,
  description: PropTypes.string,
  footerActions: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  fullScreen: PropTypes.bool,
  fullWidth: PropTypes.bool,
  id: PropTypes.string.isRequired,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string,
};

CloseableDialog.defaultProps = {
  closeOnBackdropClick: true,
  contentClassName: null,
  description: null,
  fullScreen: false,
  fullWidth: false,
  maxWidth: 'sm',
  title: '',
};

export default CloseableDialog;
