import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import ListItemButton from '@mui/material/ListItemButton';
import { faCirclePlus } from '@fortawesome/pro-duotone-svg-icons/faCirclePlus';

import { COURSE } from 'common/dattrs';
import BongoFontAwesomeIcon from 'common/components/icon/BongoFontAwesomeIcon';
import CourseAddDialog from 'components/course/CourseAddDialog';
import CourseService from 'service/course';
import { getCourseFilters, getCourseFilterFieldValue, getRawCourses } from 'redux/course/course.selectors';
import { getCurrentOrg } from 'redux/org/org.selectors';
import Permissions from 'common/utils/permissions';
import useDialog from 'hooks/useDialog';

import CourseListItem from './CourseListItem';
import CourseListOptions from './CourseListOptions';
import styles from './CourseList.module.scss';
function CourseList(props) {
  const { courses } = props;
  const filters = useSelector(getCourseFilters);
  const filterValue = useSelector(getCourseFilterFieldValue);
  const org = useSelector(getCurrentOrg);
  const rawCourses = useSelector(getRawCourses);

  const addDialog = useDialog(CourseAddDialog, { onCreate: onCreateCourse });

  async function onCreateCourse(name) {
    try {
      await CourseService.createCourse(name, org.id);
      addDialog.hide();
    } catch (err) {
      console.error(`Error creating course '${name}':`, err);
    }
  }

  function isListFiltered() {
    let count = filters.role.length + filters.autoEnrollment.length;
    if (!_.isEmpty(filterValue)) {
      count++;
    }

    return count > 0;
  }

  return (
    <div className={styles.container}>
      <div className={styles.filterContainer}>
        <CourseListOptions />
      </div>

      <div className={styles.createContainer}>
        {Permissions.isCourseCreateVisible(rawCourses) ? (
          <ListItemButton
            {...COURSE.create}
            aria-label={t`Create Course`}
            component='a'
            className={styles.createButton}
            onClick={() => {
              addDialog.show();
            }}
          >
            <BongoFontAwesomeIcon
              className={styles.addIcon}
              icon={faCirclePlus}
              size={28}
              style={{ '--fa-primary-color': '#000', '--fa-secondary-color': '#888' }}
            />
            <span className={styles.name}>{t`Create Course`}</span>
          </ListItemButton>
        ) : (
          <div className={styles.separator}></div>
        )}
      </div>

      <div {...COURSE.list.items} role='list' className={styles.courseList}>
        {isListFiltered() && courses.length === 0 && (
          <div className={styles.emptyCourseList}>
            <span>{t`No courses found`}</span>
          </div>
        )}
        {_.map(courses, (course) => {
          return <CourseListItem key={`courseListItem[${course.id}]`} className={styles.listItem} course={course} />;
        })}
      </div>
    </div>
  );
}

CourseList.propTypes = {
  courses: PropTypes.array,
};

CourseList.defaultProps = {
  courses: [],
};

export default CourseList;
