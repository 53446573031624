import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import _ from 'lodash';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';

import { SUPPORTED_LANGUAGES } from 'common/constants/general';
import styles from './NotificationSettings.module.scss';

function NotificationSettings(props) {
  const { settings, onSettingChange, instructorNotificationsVisible } = props;

  function getNotificationLangaugeSettings() {
    return (
      <div className={styles.languageContainer}>
        <div className={styles.itemContainer}>
          <div className={styles.itemLabel}>
            <span className={styles.itemTitle}>{t`Language`}</span>
            <span className={styles.itemDesc}>{t`Select which language to receive notifications in`}</span>
          </div>
          <div className={styles.itemActions}>
            <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
              <Select
                value={_.get(settings, 'locale')}
                onChange={(e) => {
                  onSettingChange('locale', e.target.value);
                }}
              >
                {_.sortBy(SUPPORTED_LANGUAGES, [(i) => i18n._(i.label)]).map((item) => {
                  return (
                    <MenuItem key={`notification_locale_${item.value}`} value={item.value}>
                      {i18n._(item.label)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
    );
  }

  function getNotificationSetting({ title, description, toggleAriaLabel, settingPath }) {
    return (
      <div className={styles.itemContainer}>
        <div className={styles.itemLabel}>
          <span className={styles.itemTitle}>{title}</span>
          <span className={styles.itemDesc}>{description}</span>
        </div>
        <div className={styles.itemActions}>
          <Switch
            inputProps={{ 'aria-label': toggleAriaLabel }}
            checked={_.get(settings, settingPath)}
            onChange={(e) => {
              onSettingChange(settingPath, e.target.checked);
            }}
          />
        </div>
      </div>
    );
  }

  function getEmailNotificationSettings() {
    return (
      <div className={styles.emailContainer}>
        <h4>{t`Email notifications`}</h4>
        <div className={styles.emailOptions}>
          {getNotificationSetting({
            title: t`Assignment evaluated`,
            description: t`Notify when my submission has been evaluated`,
            toggleAriaLabel: t`Assignment evaluated toggle`,
            settingPath: 'assignment.evaluated',
          })}

          {instructorNotificationsVisible &&
            getNotificationSetting({
              title: t`Assignment submitted`,
              description: t`Notify when a learner has submitted an assignment`,
              toggleAriaLabel: t`Assignment submitted toggle`,
              settingPath: 'assignment.submitted',
            })}
        </div>
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <h3>{t`Notifications`}</h3>
      {getNotificationLangaugeSettings()}
      <div className={styles.sep} />
      {getEmailNotificationSettings()}
    </div>
  );
}

NotificationSettings.propTypes = {
  instructorNotificationsVisible: PropTypes.bool,
  onSettingChange: PropTypes.func,
  settings: PropTypes.shape({
    locale: PropTypes.string,
    assignment: PropTypes.shape({
      submitted: PropTypes.bool,
      evaluated: PropTypes.bool,
    }),
  }),
};

NotificationSettings.defaultProps = {
  instructorNotificationsVisible: false,
};

export default NotificationSettings;
