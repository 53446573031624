import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';

import { COURSE } from 'common/dattrs';
import CourseService from 'service/course';
import { copyAsyncResultsToClipboard } from 'common/utils/domUtils';
import LoadingButton from 'common/components/button/LoadingButton';
import NotificationService, { NOTIFICATION_TYPES } from 'service/notification';

import styles from './CourseLinkSection.module.scss';

function CourseLinkSection(props) {
  const { courseId } = props;
  const [generatingLink, setGeneratingLink] = useState(false);

  async function onCopy() {
    setGeneratingLink(true);
    try {
      await copyAsyncResultsToClipboard(CourseService.generateLink({ courseId }));
      NotificationService.notify({ message: t`Course link copied` });
    } catch (e) {
      NotificationService.notify({ message: t`Could not copy course link`, severity: NOTIFICATION_TYPES.ERROR });
    }
    setGeneratingLink(false);
  }

  return (
    <div className={styles.container}>
      <h3>{t`Course Link`}</h3>
      <div className={styles.contents}>
        <p className={styles.description}>
          {t`Sharing or embedding the course link allows users to directly launch this course content.`}
        </p>
        <div className={styles.actions}>
          <LoadingButton
            {...COURSE.settings.copyLink}
            className={styles.copyButton}
            disabled={generatingLink}
            loading={generatingLink}
            variant='outlined'
            onClick={onCopy}
          >{t`Copy Link`}</LoadingButton>
        </div>
      </div>
    </div>
  );
}

CourseLinkSection.propTypes = {
  courseId: PropTypes.string.isRequired,
};

CourseLinkSection.defaultProps = {
  courseId: '',
};

export default CourseLinkSection;
