import React, { memo, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import _ from 'lodash';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import Menu from '@mui/material/Menu';
import { faDownload } from '@fortawesome/pro-solid-svg-icons/faDownload';
import { faEllipsisH } from '@fortawesome/pro-duotone-svg-icons/faEllipsisH';
import { faGear } from '@fortawesome/pro-solid-svg-icons/faGear';
import { faLinkSimple } from '@fortawesome/pro-solid-svg-icons/faLinkSimple';
import { faCopy } from '@fortawesome/pro-regular-svg-icons/faCopy';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons/faCircleExclamation';
import useMediaQuery from '@mui/material/useMediaQuery';

import BongoFontAwesomeIcon from 'common/components/icon/BongoFontAwesomeIcon';
import CourseCopyDialog from 'components/course/copy/CourseCopyDialog';
import CourseService from 'service/course';
import CourseSettingsDialog from 'components/course/settings/CourseSettingsDialog';
import Highlight from 'common/components/general/Highlight';
import MenuItem from 'common/components/menu/MenuItem';
import NotificationService, { NOTIFICATION_TYPES } from 'service/notification';
import Permissions from 'common/utils/permissions';
import { COPY_STATES } from 'common/constants/copyStates';
import { COURSE } from 'common/dattrs';
import { copyAsyncResultsToClipboard } from 'common/utils/domUtils';
import { isSuperUser } from 'redux/user/user.selectors';
import { isAdmin } from 'redux/org/org.selectors';
import { getCourseFilterFieldValue, getRawCourses } from 'redux/course/course.selectors';
import { getLabelForRole } from 'common/utils/user';

import EnrollmentStatus from './EnrollmentStatus';
import styles from './CourseListItem.module.scss';

function CourseListItem(props) {
  const { className, course } = props;
  const { name: courseName, role, copyInfo } = course;
  const [anchorEl, setAnchorEl] = useState(null);
  const [hasFocus, setHasFocus] = useState(false);
  const [courseSettingsVisible, setCourseSettingsVisible] = useState(false);
  const [generatingLink, setGeneratingLink] = useState(false);
  const [downloadingSCORM, setDownloadingSCORM] = useState(false);
  const [copyCourseVisible, setCopyCourseVisible] = useState(false);
  const [inErrorState, setInErrorState] = useState(true);

  const navigate = useNavigate();
  const _isSuperUser = useSelector(isSuperUser);
  const _isAdmin = useSelector(isAdmin);
  const mediumWidth = useMediaQuery('(min-width:600px)');
  const smallWidth = useMediaQuery('(min-width:480px)');
  const usersRole = getRoleLabel(role);
  const filterValue = useSelector(getCourseFilterFieldValue);
  const rawCourses = useSelector(getRawCourses);

  useLayoutEffect(() => {
    setInErrorState([COPY_STATES.FAILED].includes(copyInfo?.state));
  }, [copyInfo]);

  function handleMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  async function onCourseLinkCopy() {
    setGeneratingLink(true);
    try {
      await copyAsyncResultsToClipboard(CourseService.generateLink({ courseId: course.id }));
      NotificationService.notify({ message: t`Course link copied` });
    } catch (e) {
      NotificationService.notify({ message: t`Could not copy course link`, severity: NOTIFICATION_TYPES.ERROR });
    }
    setGeneratingLink(false);
    handleMenuClose();
  }

  async function onDownloadSCORM() {
    setDownloadingSCORM(true);
    const result = await CourseService.generateLink({ courseId: course.id });
    const scorm = await CourseService.downloadSCORMPackage({ courseId: course.id, url: result });
    window.open(scorm, '_self');
    NotificationService.notify({ message: t`SCORM package download started` });
    setDownloadingSCORM(false);
    handleMenuClose();
  }

  function routeToAssignments() {
    CourseService.setActiveCourse(course);
    if (_isSuperUser) {
      const path = generatePath(`:courseId`, {
        courseId: course.id,
      });
      navigate(path);
    } else {
      const path = generatePath(`/courses/:courseId`, {
        courseId: course.id,
      });
      navigate(path);
    }
  }

  function getRoleLabel(role) {
    if (_isAdmin || _isSuperUser) {
      return null;
    }

    return getLabelForRole(role);
  }

  function getActionsMenu() {
    const groupOneActions = [];
    const groupTwoActions = [];
    const groupThreeActions = [];

    if (Permissions.isCourseCopyVisible(role)) {
      groupOneActions.push(
        <MenuItem
          {...COURSE.list.item.copy}
          label={t`Make a copy`}
          key={`courseCopyListItem[${course.id}]`}
          icon={faCopy}
          onClick={() => {
            setCopyCourseVisible(true);
            handleMenuClose();
          }}
        />
      );
    }

    if (Permissions.isCourseCopyLinkVisible(role)) {
      groupTwoActions.push(
        <MenuItem
          {...COURSE.list.item.copyLink}
          key={`courseCopyLinkListItem[${course.id}]`}
          disabled={generatingLink}
          loading={generatingLink}
          onClick={onCourseLinkCopy}
          label={t`Copy course link`}
          icon={faLinkSimple}
        />
      );
    }

    if (Permissions.isCourseDownloadSCORMVisible(role)) {
      groupTwoActions.push(
        <MenuItem
          {...COURSE.list.item.downloadSCORM}
          key={`courseDownloadSCORMListItem[${course.id}]`}
          disabled={downloadingSCORM}
          loading={downloadingSCORM}
          onClick={onDownloadSCORM}
          label={t`Download SCORM package`}
          icon={faDownload}
        />
      );
    }

    if (Permissions.isCourseSettingsVisible(role)) {
      groupThreeActions.push(
        <MenuItem
          {...COURSE.list.item.settings}
          key={`courseSettingsListItem[${course.id}]`}
          onClick={() => {
            setCourseSettingsVisible(true);
            handleMenuClose();
          }}
          icon={faGear}
          label={t`Settings`}
        />
      );
    }

    if (groupOneActions.length && (groupTwoActions.length || groupThreeActions.length)) {
      groupOneActions.push(<Divider key={`courseListItemGroupOneDivider[${course.id}]`} />);
    }

    if (groupTwoActions.length && groupThreeActions.length) {
      groupTwoActions.push(<Divider key={`courseListItemGroupTwoDivider[${course.id}]`} />);
    }

    const menuActions = [...groupOneActions, ...groupTwoActions, ...groupThreeActions];
    if (_.isEmpty(menuActions)) {
      return null;
    }

    return (
      <>
        <Button
          {...COURSE.list.item.menu}
          id={`course-options[${course.id}]`}
          aria-label={t`${courseName} options`}
          aria-controls={anchorEl ? `course-options-menu[${course.id}]` : undefined}
          aria-haspopup='true'
          aria-expanded={anchorEl ? 'true' : undefined}
          className={styles.menuButton}
          onClick={handleMenuOpen}
        >
          <BongoFontAwesomeIcon icon={faEllipsisH} size={24} />
        </Button>
        <Menu
          id={`course-options-menu[${course.id}]`}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          MenuListProps={{
            'aria-labelledby': `course-options[${course.id}]`,
          }}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {[...menuActions]}
        </Menu>
      </>
    );
  }

  const showRoleColumn = !_isSuperUser && !_isAdmin && smallWidth;
  return (
    <div role='listitem' className={cx(styles.item, className)}>
      <div className={styles.contents}>
        <ListItemButton
          {...COURSE.list.item.launch.q({ id: course.id, name: courseName, role })}
          component='a'
          onClick={routeToAssignments}
          onFocus={() => {
            setHasFocus(true);
          }}
          onBlur={() => {
            setHasFocus(false);
          }}
          className={cx(styles.details, showRoleColumn && styles.showRoleColumn)}
        >
          <div className={styles.nameContainer}>
            <span className={styles.name}>
              <span aria-hidden='true' className={styles.label}>{t`Name`}</span>
              <span className={styles.name} aria-label={',' + t`Course name` + ',' + courseName}>
                <Highlight search={filterValue}>{courseName}</Highlight>
              </span>
            </span>
            {inErrorState && (
              <div className={styles.errorContainer}>
                <BongoFontAwesomeIcon className={styles.errorStateIcon} icon={faCircleExclamation} size={21} />
                <span className={styles.errorMessage}>{t`Error copying an assignment in this course`}</span>
              </div>
            )}
          </div>

          {mediumWidth && Permissions.isCourseAutoEnrollmentStatusVisibleForAnyCourse(rawCourses) && (
            <div className={styles.enrollmentContainer}>
              {Permissions.isCourseAutoEnrollmentStatusVisible(role) && (
                <>
                  <span aria-hidden='true' className={styles.label}>{t`Auto Enrollment`}</span>
                  <EnrollmentStatus
                    autoEnrollment={_.get(course, 'autoEnrollment')}
                    showLabel={false}
                    showTooltip={hasFocus}
                  />
                </>
              )}
            </div>
          )}

          {showRoleColumn && (
            <div className={styles.roleContainer}>
              <span aria-hidden='true' className={styles.label}>{t`Role`}</span>
              <span aria-label={',' + t`Role: ${usersRole}`} className={styles.role}>
                {usersRole}
              </span>
            </div>
          )}
        </ListItemButton>
        <div className={styles.actionsContainer}>{getActionsMenu()}</div>
      </div>

      {courseSettingsVisible && (
        <CourseSettingsDialog
          courseId={course.id}
          onHide={() => {
            setCourseSettingsVisible(false);
          }}
        />
      )}

      {copyCourseVisible && (
        <CourseCopyDialog
          course={course}
          onCopyComplete={() => {
            NotificationService.notify({
              key: 'copy-course-success',
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              autoHideDuration: 6000,
              message: t`Course copy has started`,
            });
          }}
          onHide={() => {
            setCopyCourseVisible(false);
          }}
        />
      )}
    </div>
  );
}

CourseListItem.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
};

CourseListItem.defaultProps = {
  className: null,
};

export default memo(CourseListItem);
