import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { t } from '@lingui/macro';
import cx from 'classnames';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { faSignOut } from '@fortawesome/pro-duotone-svg-icons/faSignOut';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons/faQuestion';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown';
import { faSlidersSimple } from '@fortawesome/pro-solid-svg-icons/faSlidersSimple';

import Avatar from 'common/components/avatar/Avatar';
import { HELP, USER } from 'common/dattrs';
import BongoFontAwesomeIcon from 'common/components/icon/BongoFontAwesomeIcon';
import Permissions from 'common/utils/permissions';
import { getUserFirstName, getUserLastName, getUserEmail } from 'redux/user/user.selectors';
import UserSettingsDialog from 'components/user/UserSettingsDialog';
import useDialog from 'hooks/useDialog';

import AppBarNotifications from './AppBarNotifications';
import styles from './AppBar.module.scss';

const ThemedAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
}));

const BONGO_HELP_LINK = 'https://bongolearn.zendesk.com/';

export default function BongoAppBar(props) {
  const { className, leftActions } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [logout, setLogout] = useState(false);

  const firstName = useSelector(getUserFirstName);
  const lastName = useSelector(getUserLastName);
  const email = useSelector(getUserEmail);
  const fullName = `${firstName} ${lastName}`;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const settingsDialog = useDialog(UserSettingsDialog);

  return (
    <Box>
      {logout && <Navigate to='/logout' />}
      <ThemedAppBar className={cx(styles.appBar, className)} position='fixed'>
        <Toolbar className={styles.toolbar}>
          <div className={styles.leftContent}>{leftActions}</div>
          <div className={styles.rightContent}>
            <AppBarNotifications />
            <ListItemButton {...USER.options} onClick={handleMenu} className={styles.userOptions}>
              <IconButton
                size='medium'
                aria-label={t`current user options`}
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleMenu}
                color='inherit'
              >
                <Avatar className={styles.avatar} firstName={firstName} lastName={lastName} />
              </IconButton>
              <BongoFontAwesomeIcon icon={faAngleDown} size={18} />
            </ListItemButton>
            <Popover
              id={'user-options-menu'}
              open={menuOpen}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <div className={styles.userMenuContainer}>
                <div className={styles.userInfoContainer}>
                  <Avatar className={styles.avatar} size={64} firstName={firstName} lastName={lastName} />
                  <Typography {...USER.fullname} className={styles.username}>
                    {fullName}
                  </Typography>
                  <Typography {...USER.email} className={styles.email}>
                    {email}
                  </Typography>
                </div>

                <List className={styles.actions}>
                  {Permissions.isUserSettingsVisible() && (
                    <ListItem className={styles.actionItem}>
                      <ListItemButton
                        {...USER.settings.launch}
                        onClick={() => {
                          settingsDialog.show();
                          handleClose();
                        }}
                      >
                        <ListItemIcon>
                          <BongoFontAwesomeIcon icon={faSlidersSimple} size={24} />
                        </ListItemIcon>
                        <ListItemText primary={t`Preferences`} />
                      </ListItemButton>
                    </ListItem>
                  )}
                  <ListItem className={styles.actionItem}>
                    <ListItemButton
                      {...HELP}
                      onClick={() => {
                        window.open(BONGO_HELP_LINK);
                      }}
                    >
                      <ListItemIcon>
                        <BongoFontAwesomeIcon icon={faQuestion} size={24} />
                      </ListItemIcon>
                      <ListItemText primary={t`Help`} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem className={styles.actionItem}>
                    <ListItemButton
                      {...USER.logout}
                      onClick={() => {
                        setLogout(true);
                      }}
                    >
                      <ListItemIcon>
                        <BongoFontAwesomeIcon icon={faSignOut} size={24} />
                      </ListItemIcon>
                      <ListItemText primary={t`Logout`} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </div>
            </Popover>
          </div>
        </Toolbar>
      </ThemedAppBar>
      {/* <Offset /> */}
    </Box>
  );
}

BongoAppBar.propTypes = {
  className: PropTypes.string,
  leftActions: PropTypes.array,
};

BongoAppBar.defaultProps = {
  className: null,
  leftActions: [],
};
