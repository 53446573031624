import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import CloseableDialog from 'common/components/dialog/CloseableDialog';
import CourseService from 'service/course';
import DialogService from 'service/dialog';
import UserService from 'service/user';
import Loader from 'common/components/loader/Loader';
import Permissions from 'common/utils/permissions';
import { QUERY_PARAM_DIALOG, QUERY_PARAM_DIALOG_CONTEXT } from 'common/constants/router';
import { getBongoLang } from 'locale/utils';
import { getCurrentOrg } from 'redux/org/org.selectors';
import { getCourses } from 'redux/course/course.selectors';

import NotificationSettings from './NotificationSettings';
import styles from './UserSettingsDialog.module.scss';

const DEFAULT_SETTINGS = {
  notification: {
    locale: getBongoLang(),
    assignment: {
      evaluated: false,
      submitted: false,
    },
  },
};

function UserSettingsDialog(props) {
  const { dialogId } = props;
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [settings, setSettings] = useState(DEFAULT_SETTINGS);
  const org = useSelector(getCurrentOrg);

  const courses = useSelector(getCourses);
  const instructorNotificationsVisible = Permissions.isInstructorNotificationSettingsVisible(courses);
  const isUserSettingsVisible = Permissions.isUserSettingsVisible();

  useEffect(() => {
    async function loadSettings() {
      setLoading(true);
      await CourseService.fetchCourses(org.id);
      const _settings = await UserService.fetchUserSettings(org.id);
      if (_settings) {
        setSettings(_settings);
      }
      setLoading(false);
    }

    if (org?.id && isUserSettingsVisible) {
      loadSettings();
    }
  }, [org?.id, isUserSettingsVisible]);

  function onNotificationSettingChange(propPath, value) {
    const notificationSettings = { ...settings.notification };
    _.set(notificationSettings, propPath, value);

    const settingPayload = { ...settings, notification: notificationSettings };
    UserService.updateUserSettings(org.id, settingPayload);
    setSettings(settingPayload);
  }

  function closeDialog() {
    if (searchParams.has(QUERY_PARAM_DIALOG)) {
      if (searchParams.has(QUERY_PARAM_DIALOG)) {
        searchParams.delete(QUERY_PARAM_DIALOG);
      }
      if (searchParams.has(QUERY_PARAM_DIALOG_CONTEXT)) {
        searchParams.delete(QUERY_PARAM_DIALOG_CONTEXT);
      }
      setSearchParams(searchParams);
    }

    // when using the useDialog hook, the id will be populated.
    // that means the dialog wasn't launched from a route
    if (dialogId) {
      DialogService.hideDialog(dialogId);
    }
  }

  if (!isUserSettingsVisible) {
    closeDialog();
    return null;
  }

  return (
    <CloseableDialog
      id='user-settings-dialog'
      className={styles.dialog}
      closeOnBackdropClick={false}
      title={t`Preferences`}
      fullScreen={false}
      fullWidth={true}
      maxWidth={'sm'}
      onHide={() => {
        closeDialog();
      }}
    >
      {loading && <Loader />}
      <div className={styles.content}>
        <NotificationSettings
          onSettingChange={onNotificationSettingChange}
          settings={settings.notification}
          instructorNotificationsVisible={instructorNotificationsVisible}
        />
      </div>
    </CloseableDialog>
  );
}

UserSettingsDialog.propTypes = {
  dialogId: PropTypes.string,
};

export default UserSettingsDialog;
