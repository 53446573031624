import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core'

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';

import BongoFontAwesomeIcon from 'common/components/icon/BongoFontAwesomeIcon';
import { dattrsFromProps, COURSE } from 'common/dattrs';
import { getLabelForRole } from 'common/utils/user';
import { ROLES } from 'common/constants/general';

import styles from './MemberRoleSelect.module.scss';

export default function MemberRoleSelect(props) {
  const { disabled, fullWidth, inputRef, onChange, selectedRole, size, sx, variant } = props;

  function getMenuItem(role) {
    const { label, description, value } = role;
    return (
      <MenuItem key={value} {...COURSE.members.role.q({ role: value })} value={value}>
        <div className={styles.menuItem}>
          <div className={styles.selectedContainer}>
            {value === selectedRole && (
              <ListItemIcon>
                <BongoFontAwesomeIcon size={21} icon={faCheck} />
              </ListItemIcon>
            )}
          </div>
          <div className={styles.contents}>
            <div className={styles.labelContainer}>
              <span>{i18n._(label)}</span>
            </div>
            <div className={styles.descriptionContainer}>
              <span>{i18n._(description)}</span>
            </div>
          </div>
        </div>
      </MenuItem>
    );
  }

  return (
    <FormControl fullWidth={fullWidth} size={size} sx={sx}>
      <InputLabel id='course-members-role-field-label'>{t`Role`}</InputLabel>
      <Select
        {...dattrsFromProps(props)}
        labelId='course-members-role-field-label'
        label={t`Role`}
        disabled={disabled}
        inputRef={inputRef}
        renderValue={getLabelForRole}
        value={selectedRole}
        variant={variant}
        onChange={(event) => onChange(event.target.value)}
      >
        {ROLES.map((role) => {
          return getMenuItem(role);
        })}
      </Select>
    </FormControl>
  );
}

MemberRoleSelect.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  inputRef: PropTypes.any,
  selectedRole: PropTypes.string.isRequired,
  size: PropTypes.string,
  sx: PropTypes.object,
  variant: PropTypes.string,
};

MemberRoleSelect.defaultProps = {
  disabled: false,
  fullWidth: false,
  size: 'medium',
  sx: {},
  variant: 'outlined',
};
