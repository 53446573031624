import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';

import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import SvgIcon from '@mui/material/SvgIcon';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import { faComments } from '@fortawesome/pro-solid-svg-icons/faComments';

import { ACTIVITY } from 'common/dattrs';
import { ReactComponent as IV } from 'assets/images/interactive-video.svg';
import BongoFontAwesomeIcon from 'common/components/icon/BongoFontAwesomeIcon';
import CloseableDialog from 'common/components/dialog/CloseableDialog';
import { INDIVIDUAL, GROUP, QA, INTERACTIVE_VIDEO } from 'common/constants/activityTypes';

import styles from './CreateActivityDialog.module.scss';


function AssignmentItem(props) {
  const { name, icon, description, type, onCreate } = props;
  return (
    <ListItemButton
      {...ACTIVITY.createDialog.activity.q({ type, name })}
      onClick={() => onCreate(type)}
      className={styles.assignmentItem}
    >
      <div className={styles.icon}>{icon}</div>
      <h2 className={styles.name}>{name}</h2>
      <span className={styles.description}>{description}</span>
    </ListItemButton>
  );
}

AssignmentItem.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
  onCreate: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

function CreateActivityDialog(props) {
  const { onCreate, onHide } = props;
  return (
    <CloseableDialog
      {...ACTIVITY.createDialog.container}
      id='create-activity-dialog'
      fullScreen
      onHide={onHide}
      title={t`Create Assignment`}
      description={t`Select the assignment type you want to create`}
    >
      <div className={styles.content}>
        <List className={styles.assignmentTypes}>
          <AssignmentItem
            name={t`Individual`}
            type={INDIVIDUAL}
            icon={<BongoFontAwesomeIcon size={64} icon={faUser} />}
            description={t`Learners can record or upload a video of themselves, practice presenting on a topic, or demonstrate a specific skill.`}
            onCreate={onCreate}
          />
          <AssignmentItem
            name={t`Question & Answer`}
            type={QA}
            icon={<BongoFontAwesomeIcon size={64} icon={faComments} />}
            description={t`Learners are presented with video or text prompts and given a set amount of time to respond.`}
            onCreate={onCreate}
          />
          <AssignmentItem
            name={t`Group`}
            type={GROUP}
            icon={<BongoFontAwesomeIcon size={64} icon={faUsers} />}
            description={t`Learners collaborate in real-time or on their own time to create a video or other content for submission.`}
            onCreate={onCreate}
          />
          <AssignmentItem
            name={t`Interactive Video`}
            type={INTERACTIVE_VIDEO}
            icon={<SvgIcon className={styles.iv} component={IV} inheritViewBox />}
            description={t`Learners watch a facilitator-provided video and respond with multiple choice or video answers at specific points during the video.`}
            onCreate={onCreate}
          />
        </List>
      </div>
    </CloseableDialog>
  );
}

CreateActivityDialog.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default CreateActivityDialog;
