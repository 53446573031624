import React, { useEffect } from 'react';
import { generatePath } from 'react-router';
import { useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';

import CourseService from 'service/course';
import Loader from 'common/components/loader/Loader';
import logger from 'common/utils/logger';

const LOGGER_PREFIX = 'Launch';

export default function Launch() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function testEnrollmentToken() {
      const token = searchParams.get('token');

      if (!token) {
        logger.warn(`${LOGGER_PREFIX}::enrollmentToken, Token not found in URL params`);
        window.sessionStorage.setItem('bongo-pre-login-destination', '/');
        navigate('/');
        return;
      }

      const details = await CourseService.getJwtTokenDetails(token);
      if (!details) {
        logger.warn(`${LOGGER_PREFIX}::enrollmentToken, Failed to get details from token`);
        window.sessionStorage.setItem('bongo-pre-login-destination', '/');
        navigate('/');
        return;
      }

      const courseId = _.get(details, 'courseId');
      if (courseId) {
        logger.debug(`${LOGGER_PREFIX}::enrollmentToken, Attempting to auto enroll current user in course: ${courseId}`);
        const result = await CourseService.enrollCurrentUserInCourse(courseId);
        logger.debug(`${LOGGER_PREFIX}::enrollmentToken, After call to enroll user with result:`, result);

        const activityId = _.get(details, 'activityId');
        if (activityId) {
          logger.debug(
            `${LOGGER_PREFIX}::enrollmentToken, Launching user to courseId: ${courseId}, activityId: ${activityId}`
          );

          const path = generatePath(`/courses/:courseId/activity/:activityId`, {
            courseId,
            activityId
          });
          navigate(path);
          return;
        } else {
          logger.debug(`${LOGGER_PREFIX}::enrollmentToken, Launching user to courseId: ${courseId}`);
          const path = generatePath(`/courses/:courseId`, {
            courseId,
          });
          navigate(path);
          return;
        }
      }

      // just a catch-all in case something up above didn't work
      logger.warn(`${LOGGER_PREFIX}::enrollmentToken, Could not determine auto enrollment path. Falling back to root path`);
      navigate('/');
    }

    testEnrollmentToken();
  }, [searchParams, navigate]);

  return <Loader />;
}
