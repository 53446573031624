import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { t } from '@lingui/macro';
import _ from 'lodash';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft';

import { ACTIVITY } from 'common/dattrs';
import ActivityService from 'service/activity';
import AppBar from 'components/appbar/AppBar';
import BongoFontAwesomeIcon from 'common/components/icon/BongoFontAwesomeIcon';
import BongoLtiLaunch from 'launch/BongoLtiLaunch';
import CloseableDialog from 'common/components/dialog/CloseableDialog';
import Loader from 'common/components/loader/Loader';
import { INDIVIDUAL, GROUP, QA, INTERACTIVE_VIDEO } from 'common/constants/activityTypes';

import styles from './ActivitySettingsDialog.module.scss';

const DIRTY_PROP = 'isDirty';
const SAVE_STATUS_PROP = 'saveStatus';
const SAVING_STATE = 'SAVING';
const SAVED_STATE = 'SUCCESS';
const ERROR_STATE = 'ERROR';

function getActivityType(type) {
  switch (type) {
    case INDIVIDUAL:
      return 'individual';
    case GROUP:
      return 'group';
    case QA:
      return 'q-and-a';
    case INTERACTIVE_VIDEO:
      return 'interactive-video';
    default:
      return 'unknown';
  }
}

export default function ActivitySettingsDialog(props) {
  const { courseId } = useParams();
  const { activityId, onHide, resourceLinkId, type } = props;
  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);

  async function onIFrameEvent(data) {
    const changedState = _.get(data, 'changedState');
    if (changedState) {
      if (_.has(changedState, DIRTY_PROP)) {
        setDirty(changedState.isDirty);
      }

      if (_.has(changedState, SAVE_STATUS_PROP)) {
        if (changedState[SAVE_STATUS_PROP] === SAVING_STATE) {
          setLoading(true);
        } else if (changedState[SAVE_STATUS_PROP] === SAVED_STATE) {
          const activityName = _.get(data, 'form.name');
          const resourceLinkId = _.get(data, 'form.linkId');
          if (activityId && activityName) {
            await ActivityService.updateActivity({ id: activityId, name: activityName });
          } else if (resourceLinkId && activityName) {
            await ActivityService.createActivity({
              courseId,
              resourceLinkId,
              name: activityName,
              type,
            });
          }
          setLoading(false);
          onHide();
        } else if (changedState[SAVE_STATUS_PROP] === ERROR_STATE) {
          setLoading(false);
        }
      }
    }
  }

  const onNagivateAway = useCallback(() => {
    if (!dirty) {
      onHide();
    } else {
      setConfirmDialogVisible(true);
    }
  }, [dirty, onHide]);

  const onBeforeUnload = useCallback(
    (e) => {
      if (!dirty) return null;

      // Some browsers expect the confirmation message to be returned from this function call
      // Some browsers expect the confirmation message to be set in the event object passed into this function
      // Some browsers display their own confirmation message and don't use this string
      const confirmationMessage = t`Are you sure you want to leave this page? Changes you made may not be saved.`;
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    },
    [dirty]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [onBeforeUnload]);

  return (
    <Dialog fullScreen open={true} onClose={onHide}>
      <Loader visible={loading} />
      <AppBar
        className={styles.appBar}
        leftActions={[
          <div className={styles.actionsContainer} key={'leftActions'}>
            <div>
              <ListItemButton
                {...ACTIVITY.backToActivityList}
                key='back-btn'
                aria-label={t`Back`}
                onClick={onNagivateAway}
                className={styles.backButton}
              >
                <BongoFontAwesomeIcon icon={faArrowLeft} size={24} />
                <Typography className={styles.username}>{t`Assignments`}</Typography>
              </ListItemButton>
            </div>
          </div>,
        ]}
      />

      {resourceLinkId ? (
        <BongoLtiLaunch
          courseId={courseId}
          resourceLinkId={resourceLinkId}
          onIFrameEvent={onIFrameEvent}
          view={'activity-settings'}
        />
      ) : (
        <BongoLtiLaunch
          parameters={{ type: getActivityType(type) }}
          courseId={courseId}
          onIFrameEvent={onIFrameEvent}
        />
      )}

      {confirmDialogVisible && (
        <CloseableDialog
          id='leave-page-dialog'
          title={t`Leave this page?`}
          onHide={() => {
            setConfirmDialogVisible(false);
          }}
          footerActions={<Button variant='contained' color='error' onClick={onHide}>{t`Leave`}</Button>}
        >
          <DialogContentText>{t`You have unsaved changes. Are you sure you want to leave?`}</DialogContentText>
        </CloseableDialog>
      )}
    </Dialog>
  );
}

ActivitySettingsDialog.propTypes = {
  activityId: PropTypes.string.isRequired,
  resourceLinkId: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  type: PropTypes.string,
};
