import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { ACTIVITY } from 'common/dattrs';
import ListItemButton from '@mui/material/ListItemButton';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons/faCircleExclamation';

import ActivityService from 'service/activity';
import ActivityListItemActions from 'components/activity/ActivityListItemActions';
import BongoFontAwesomeIcon from 'common/components/icon/BongoFontAwesomeIcon';
import Highlight from 'common/components/general/Highlight';
import InlineConfirm from 'common/components/list/InlineConfirm';
import { COPY_STATES } from 'common/constants/copyStates';
import { isSuperUser } from 'redux/user/user.selectors';
import { getCurrentOrg } from 'redux/org/org.selectors';
import { getActivityFilterFieldValue } from 'redux/activity/activity.selectors';
import { INDIVIDUAL, GROUP, QA, INTERACTIVE_VIDEO } from 'common/constants/activityTypes';
import { ROLE_LEARNER } from 'common/constants/general';

import styles from './ActivityListItem.module.scss';

function ActivityListItem(props) {
  const { activity, courseRole } = props;
  const {
    name: assignmentName,
    id: activityId,
    courseId,
    type,
    states: { visible },
    copyInfo,
  } = activity;

  const _isSuperUser = useSelector(isSuperUser);
  const org = useSelector(getCurrentOrg);
  const orgId = org?.id;
  const [showVisibilityConfirm, setShowVisibilityConfirm] = useState(false);
  const [togglingVisibility, setTogglingVisibility] = useState(false);
  const [inErrorState, setInErrorState] = useState(true);

  const filterValue = useSelector(getActivityFilterFieldValue);
  const navigate = useNavigate();
  const assignmentType = getTypeLabel(type);
  const containerRef = React.useRef(null);

  React.useLayoutEffect(() => {
    setInErrorState([COPY_STATES.FAILED].includes(copyInfo?.state));
  }, [copyInfo]);

  const routeToAssignment = (role) => {
    if (_isSuperUser) {
      const path = generatePath(`/institutions/:institutionId/courses/:courseId/activity/:activityId`, {
        courseId,
        activityId,
        institutionId: orgId,
      });
      navigate(path);
    } else {
      const path = generatePath(`/courses/:courseId/activity/:activityId`, {
        courseId,
        activityId,
      });

      navigate(path, { state: { role } });
    }
  };


  function getTypeLabel(type) {
    switch (type) {
      case INDIVIDUAL:
        return t`Individual`;
      case GROUP:
        return t`Group`;
      case QA:
        return t`Question & Answer`;
      case INTERACTIVE_VIDEO:
        return t`Interactive Video`;
      default:
        return 'unknown';
    }
  }

  async function onToggleVisibility() {
    setTogglingVisibility(true);
    await ActivityService.updateActivityStates({ id: activity.id, states: { visible: !visible } });
    setTogglingVisibility(false);
  }

  return (
    <div role='listitem' ref={containerRef} className={cx(props.className, styles.item)}>
      <InlineConfirm
        visible={showVisibilityConfirm}
        confirmAriaLabel={visible ? t`Yes, hide assignment` : t`Yes, make assignment visible`}
        confirmMessage={
          visible
            ? t`Are you sure you want to hide this assignment from learners?`
            : t`Are you sure you want to make this assignment visible to learners?`
        }
        onConfirm={() => {
          setShowVisibilityConfirm(false);
          onToggleVisibility();
        }}
        onCancel={() => {
          setShowVisibilityConfirm(false);
        }}
      />

      {!showVisibilityConfirm && (
        <div className={styles.contents}>
          <ListItemButton
            {...ACTIVITY.list.item.launch.q({ id: activity.id, type, name: assignmentName })}
            disabled={inErrorState}
            component='a'
            onClick={routeToAssignment}
            className={cx(styles.details)}
          >
            <div className={styles.nameContainer}>
              <span aria-hidden='true' className={styles.label}>{t`Name`}</span>
              <span className={styles.name} aria-label={',' + t`Assignment name` + ',' + assignmentName}>
                <Highlight search={filterValue}>{assignmentName}</Highlight>
              </span>
              {inErrorState && (
                <div className={styles.errorContainer}>
                  <BongoFontAwesomeIcon className={styles.errorStateIcon} icon={faCircleExclamation} size={21} />
                  <span
                    className={styles.errorMessage}
                  >{t`Error copying this assignment. You can delete this assignment and try to copy it again.`}</span>
                </div>
              )}
            </div>
            <div className={styles.typeContainer}>
              <span aria-hidden='true' className={styles.label}>{t`Type`}</span>
              <span aria-label={',' + t`Assignment type` + ',' + assignmentType} className={styles.role}>
                {assignmentType}
              </span>
            </div>
          </ListItemButton>

          <ActivityListItemActions
            activity={activity}
            courseRole={courseRole}
            onLaunchAsLearner={() => {
              routeToAssignment(ROLE_LEARNER);
            }}
            showVisibilityConfirm={showVisibilityConfirm}
            toggleVisibility={() => {
              setShowVisibilityConfirm(true);
            }}
            visibilityToggleLoading={togglingVisibility}
          />
        </div>
      )}
    </div>
  );
}

ActivityListItem.propTypes = {
  activity: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  courseRole: PropTypes.string,
};

ActivityListItem.defaultProps = {
  courseRole: null,
};

export default React.memo(ActivityListItem);
