import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { t } from '@lingui/macro';

import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { faCopy } from '@fortawesome/pro-regular-svg-icons/faCopy';
import { faCircleCheck } from '@fortawesome/pro-duotone-svg-icons/faCircleCheck';

import BongoFontAwesomeIcon from 'common/components/icon/BongoFontAwesomeIcon';
import CourseService from 'service/course';
import Tooltip from 'common/components/tooltip/Tooltip';
import { getCurrentOrg } from 'redux/org/org.selectors';
import {
  getCoursesInCopyProgress,
  getCoursesCopySuccess,
  getCourseCopyInitiatedFlag,
} from 'redux/course/course.selectors';
import logger from 'common/utils/logger';

import styles from './CourseCopyNotification.module.scss';

const TOOLTIP_VISIBLE_TIMEOUT = 3000;
const POLLING_TIMEOUT = 5000;
const FADE_TIMER = 1500;
const HIDE_TIMEOUT = 5000;

let tooltipTimer = null;
let pollingTimer = null;
let hideTimer = null;

export default function CourseCopyNotification() {
  const org = useSelector(getCurrentOrg);
  const coursesInCopyProgress = useSelector(getCoursesInCopyProgress);
  const coursesCopySuccess = useSelector(getCoursesCopySuccess);
  const courseCopyInitiated = useSelector(getCourseCopyInitiatedFlag);

  const [copyCompleted, setCopyCompleted] = useState(false);
  const [currentCoursesInCopyProgress, setCurrentCoursesInCopyProgress] = useState([]);
  const [popAnchorEl, setPopAnchorEl] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const buttonRef = useRef();

  useEffect(() => {
    return () => {
      clearTimeout(tooltipTimer);
      clearTimeout(pollingTimer);
    };
  }, []); //eslint-disable

  const startPolling = () => {
    clearTimeout(pollingTimer);

    pollingTimer = setTimeout(() => {
      CourseService.fetchCourseCopyProgress(org.id).then(
        () => {
          startPolling();
        },
        (err) => {
          logger.error(err);
          startPolling();
        }
      );
    }, POLLING_TIMEOUT);
  };

  const startHideTimer = () => {
    hideTimer = setTimeout(() => {
      setShowNotification(false);
    }, HIDE_TIMEOUT);
  };

  useEffect(() => {
    if (courseCopyInitiated) {
      CourseService.clearCourseCopyInitiatedFlag();
      setShowTooltip(true);
      tooltipTimer = setTimeout(() => {
        setShowTooltip(false);
      }, TOOLTIP_VISIBLE_TIMEOUT);
    }
  }, [courseCopyInitiated]);

  useEffect(() => {
    if (_.isEmpty(coursesInCopyProgress) && !_.isEmpty(currentCoursesInCopyProgress)) {
      setCopyCompleted(true);
    }

    setCurrentCoursesInCopyProgress(coursesInCopyProgress);
  }, [coursesInCopyProgress]); //eslint-disable-line

  useEffect(() => {
    if (coursesInCopyProgress && coursesInCopyProgress.length && org && org.id) {
      setShowNotification(true);
      startPolling();
    }
  }, [coursesInCopyProgress.length, org?.id]); //eslint-disable-line

  useEffect(() => {
    if (copyCompleted) {
      clearTimeout(pollingTimer);
      clearTimeout(tooltipTimer);

      if (!popAnchorEl) {
        setShowTooltip(true);
        tooltipTimer = setTimeout(() => {
          setShowTooltip(false);
        }, TOOLTIP_VISIBLE_TIMEOUT);
      }
    }
  }, [copyCompleted]); //eslint-disable-line

  const showPopout = (e) => {
    setShowTooltip(false);
    setPopAnchorEl(buttonRef.current);
    clearTimeout(hideTimer);
  };

  const hidePopout = (e) => {
    setPopAnchorEl(null);
    if (copyCompleted) {
      startHideTimer();
    }
  };

  const getCourseProgressChildren = () => {
    return (
      <div onMouseLeave={hidePopout} className={styles.progressContainer}>
        <div className={styles.inProgressContainer}>
          {coursesInCopyProgress.map((course) => {
            return (
              <div className={styles.item} key={course.id}>
                <CircularProgress
                  sx={{
                    position: 'absolute',
                    left: 16,
                    zIndex: 1,
                  }}
                  size={36}
                />
                <BongoFontAwesomeIcon className={styles.copyIcon} icon={faCopy} size={21} />
                <div className={styles.details}>
                  <span className={styles.name}>{course.name}</span>
                  <span className={styles.status}>{t`Copy in progress...`}</span>
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.completeContainer}>
          {coursesCopySuccess.map((course) => {
            return (
              <div className={styles.item} key={course.id}>
                <BongoFontAwesomeIcon
                  style={{
                    '--fa-primary-opacity': '1',
                    '--fa-secondary-opacity': '1',
                    '--fa-primary-color': '#0c760c',
                    '--fa-secondary-color': '#d1fac8',
                  }}
                  icon={faCircleCheck}
                  size={36}
                />
                <div className={styles.details}>
                  <span className={styles.name}>{course.name}</span>
                  <span className={styles.status}>{t`Copy complete`}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <ClickAwayListener onClickAway={hidePopout}>
      <Fade in={showNotification} timeout={FADE_TIMER}>
        <div className={styles.container}>
          <Tooltip open={showTooltip} title={copyCompleted ? t`Course copy completed` : t`Course copy started`}>
            <Button
              ref={buttonRef}
              onMouseEnter={showPopout}
              onClick={showPopout}
              className={styles.statusButton}
              variant='outlined'
            >
              {!_.isEmpty(coursesInCopyProgress) ? (
                <>
                  <CircularProgress
                    size={36}
                    sx={{
                      color: 'white',
                      position: 'absolute',
                      zIndex: 1,
                    }}
                  />
                  <BongoFontAwesomeIcon className={styles.copyIcon} icon={faCopy} size={21} />
                </>
              ) : (
                <>
                  <BongoFontAwesomeIcon
                    style={{
                      'position': 'absolute',
                      '--fa-primary-opacity': '1',
                      '--fa-secondary-opacity': '1',
                      '--fa-primary-color': '#0c760c',
                      '--fa-secondary-color': '#d1fac8',
                    }}
                    className={styles.completedIcon}
                    icon={faCircleCheck}
                    size={18}
                  />
                  <BongoFontAwesomeIcon
                    className={styles.copyIcon}
                    icon={faCopy}
                    size={28}
                  />
                </>
              )}
            </Button>
          </Tooltip>
          <div className={styles.separator}></div>
          <Popover
            id={'filter-options-menu'}
            open={Boolean(popAnchorEl)}
            anchorEl={popAnchorEl}
            onMouseEnter={showPopout}
            onMouseLeave={hidePopout}
            onClose={hidePopout}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            // sx={{
            //   pointerEvents: 'none',
            // }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {getCourseProgressChildren()}
          </Popover>
        </div>
      </Fade>
    </ClickAwayListener>
  );
}
