import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';
import cx from 'classnames';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';

import Avatar from 'common/components/avatar/Avatar';
import BongoFontAwesomeIcon from 'common/components/icon/BongoFontAwesomeIcon';
import CloseableDialog from 'common/components/dialog/CloseableDialog';
import { COURSE } from 'common/dattrs';
import logger from 'common/utils/logger';
import { getUserEmail } from 'redux/user/user.selectors';
import Tooltip from 'common/components/tooltip/Tooltip';

import styles from './AdminItem.module.scss';

const ACTION_ICON_SIZE = 22;

function AdminItem(props) {
  const {
    member: { userId, givenName, familyName },
    removeAdmin,
  } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [isPersisting, setIsPersisting] = useState(false);
  const [confirmDeleteDialogVisible, setConfirmDeleteDialogVisible] = useState(false);
  const currentUsersEmail = useSelector(getUserEmail);
  const nameVisible = Boolean(givenName || familyName);
  const roleRef = useRef('');

  useEffect(() => {
    if (isEditing) {
      roleRef.current.focus();
    }
  }, [isEditing]);

  async function _removeAdmin() {
    const memberInfo = { ...props.member };
    setIsPersisting(true);

    try {
      await removeAdmin(memberInfo);
    } catch (e) {
      logger.error('Error removing admin', e, memberInfo);
    }

    setIsPersisting(false);
    setIsEditing(false);
  }

  function getRowActions() {
    if (currentUsersEmail === userId) {
      return null;
    }

    return (
      <>
        <Tooltip title={t`Remove`}>
          <span>
            <IconButton
              {...COURSE.members.member.remove}
              aria-label={t`Remove`}
              className={styles.addEmailButton}
              onClick={() => {
                setConfirmDeleteDialogVisible(true);
              }}
            >
              <BongoFontAwesomeIcon icon={faTrash} size={ACTION_ICON_SIZE} />
            </IconButton>
          </span>
        </Tooltip>
      </>
    );
  }

  return (
    <div
      {...COURSE.members.memberItem.q({ email: userId, firstName: givenName, lastName: familyName })}
      tabIndex={0}
      role='listitem'
      className={cx(styles.memberItem, isEditing && styles.editing)}
    >
      <div className={styles.detailsContainer}>
        <Avatar
          className={styles.avatar}
          key={userId}
          size={36}
          email={userId}
          firstName={givenName}
          lastName={familyName}
        />
        <div className={styles.nameContainer}>
          {nameVisible && <span>{`${givenName} ${familyName}`}</span>}
          <span className={cx(styles.email, nameVisible && styles.nameVisible)}>{userId}</span>
        </div>
        <div className={cx(styles.actions, isEditing && styles.editing)}>{getRowActions()}</div>
      </div>

      {confirmDeleteDialogVisible && (
        <CloseableDialog
          {...COURSE.members.member.removeConfirmDialog}
          id='remove-user-confirm-dialog'
          title={t`Remove Access`}
          onHide={() => setConfirmDeleteDialogVisible(false)}
          footerActions={
            <>
              {isPersisting ? (
                <Box className={styles.progressContainer}>
                  <CircularProgress size={ACTION_ICON_SIZE} />
                </Box>
              ) : (
                <Button
                  {...COURSE.members.member.removeConfirmBtn}
                  variant='contained'
                  disabled={isPersisting}
                  color='error'
                  onClick={_removeAdmin}
                >{t`Remove Access`}</Button>
              )}
            </>
          }
        >
          <DialogContentText>{t`Are you sure you want to remove administrator access from ${userId}?`}</DialogContentText>
        </CloseableDialog>
      )}
    </div>
  );
}

AdminItem.propTypes = {
  member: PropTypes.shape({
    userId: PropTypes.string,
    givenName: PropTypes.string,
    familyName: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
  removeAdmin: PropTypes.func.isRequired,
};

export default memo(AdminItem);
