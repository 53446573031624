import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { API, graphqlOperation } from 'aws-amplify';
import { ulid } from 'ulid';
import _ from 'lodash';

import { getLaunchArgs } from 'graphql/queries';
import BongoLaunchForm from './BongoLaunchForm';
import Loader from 'common/components/loader/Loader';
import logger from 'common/utils/logger';
import Permissions from 'common/utils/permissions';
import { ROLE_LEARNER } from 'common/constants/general';
import './BongoLtiLaunch.scss';

const IFRAME_SOURCE = 'YouSeeU';
const LOGGER_PREFIX = 'BongoLtiLaunch';

const DEFAULT_LAUNCH_PREFERENCES = {
  navigationMenu: { visible: false },
  activityFormExternalLinking: { visible: false },
  activityNavigationMenu: { visible: false },
  help: { visible: false },
  activityDeletion: { visible: false },
  // theme: { palette: {}, colors: { 'page-toolbar-background': '#f2f3f5', 'page-toolbar-contrast': '#646464' } },
};

function BongoLtiLaunch(props) {
  const { courseId, onIFrameEvent, parameters, resourceLinkId, role, view } = props;
  const [launchArgs, setLaunchArgs] = useState();
  const [loaderVisible, setLoaderVisible] = useState(true);
  const _resourceLinkId = resourceLinkId || ulid();

  useEffect(() => {
    async function fetchLaunchArgs() {
      try {
        const launchParameters = {
          courseId,
          resourceLinkId: _resourceLinkId,
          view,
          parameters: JSON.stringify(parameters),
          preferences: JSON.stringify(DEFAULT_LAUNCH_PREFERENCES),
        };

        // We want to be restrictive here and make sure that if a role
        // is being passed in the user has permissions and we are only
        // supporting learner launch right now. Role based launch
        // logic could be expanded on later if needed.
        if (Permissions.isActivityLaunchAsLearnerVisible()) {
          if (role && role === ROLE_LEARNER) {
            launchParameters['role'] = ROLE_LEARNER;
          }
        }

        logger.debug(`${LOGGER_PREFIX}::fetchLaunchArgs Fetching with params`, launchParameters);
        const response = await API.graphql(graphqlOperation(getLaunchArgs, launchParameters));
        const launchArgs = _.get(response, 'data.getLaunchArgs');
        logger.debug(`${LOGGER_PREFIX}::fetchLaunchArgs Results`, launchArgs);
        setLaunchArgs(launchArgs);
      } catch (err) {
        logger.error('Error getting launch args', err);
      }
    }
    fetchLaunchArgs();
  }, []); //eslint-disable-line

  useEffect(() => {
    const handler = (event) => {
      logger.debug(`${LOGGER_PREFIX}::IFrameEvent Received event`, event.data);
      const isBongoEvent = _.get(event, 'data.source', null) === IFRAME_SOURCE;
      if (isBongoEvent && onIFrameEvent) {
        onIFrameEvent(event.data);
      }
    };

    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, [onIFrameEvent]);

  if (launchArgs == null) {
    return null;
  }

  // FIXME display error if can't get launch params
  // 1) permission denied
  // 2) gql call failed
  // 3) ???

  const { url, inputs } = launchArgs;
  const launchForm = <BongoLaunchForm url={url} inputs={inputs} />;
  const srcdoc = ReactDOMServer.renderToStaticMarkup(launchForm);

  return (
    <div id='bongo'>
      <Loader visible={loaderVisible} />
      <iframe
        id='bongo-iframe'
        srcDoc={srcdoc}
        title='bongo'
        allow='camera *; microphone *; display-capture *'
        allowFullScreen
        onLoad={() => setLoaderVisible(false)}
      />
    </div>
  );
}

BongoLtiLaunch.propTypes = {
  courseId: PropTypes.string.isRequired,
  onIFrameEvent: PropTypes.func,
  parameters: PropTypes.object,
  resourceLinkId: PropTypes.string,
  role: PropTypes.string,
  view: PropTypes.string,
};

BongoLtiLaunch.defaultProps = {
  parameter: {},
  resourceLinkId: null,
  view: 'activity',
  onIFrameEvent: () => {},
};

export default BongoLtiLaunch;
