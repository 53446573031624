import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { getDialogs } from 'redux/dialog/dialog.selectors';

function DialogManager() {
  const dialogs = useSelector(getDialogs);

  if (_.isEmpty(dialogs)) {
    return null;
  }

  return (
    <>
      {Object.entries(dialogs).map(([key, value]) => {
        return <span key={key}>{value}</span>;
      })}
    </>
  );
}

export default DialogManager;
