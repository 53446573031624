import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';

import FilterInput from 'common/components/input/FilterInput';
import { getActivityFilterFieldValue } from 'redux/activity/activity.selectors';
import { setActivityFilterFieldValue } from 'redux/activity/activity.actions';

const ActivityListFilterInput = (props) => {
  const { className } = props;
  const filterValue = useSelector(getActivityFilterFieldValue);
  const dispatch = useDispatch();

  return (
    <FilterInput
      className={className}
      placeholder={t`Find by assignment name`}
      value={filterValue}
      onValueChange={(val) => {
        dispatch(setActivityFilterFieldValue(val));
      }}
    />
  );
};

ActivityListFilterInput.propTypes = {
  className: PropTypes.string,
};

export default ActivityListFilterInput;
