import {
  SHOW_DIALOG,
  HIDE_DIALOG,
} from './dialog.types';

export function showDialog(payload) {
  return {
    type: SHOW_DIALOG,
    payload,
  };
}

export function hideDialog(dialogId) {
  return {
    type: HIDE_DIALOG,
    id: dialogId,
  };
}
