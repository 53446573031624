import store from 'redux/store';
import logger from 'common/utils/logger';

import { showDialog, hideDialog } from 'redux/dialog/dialog.actions.js';

const LOGGER_PREFIX = 'DialogService';

class DialogService {
  showDialog = (item) => {
    try {
      logger.info(`${LOGGER_PREFIX}::showDialog`, item);
      store.dispatch(showDialog(item));
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::fetchActivities`, err);
    }
  }

  hideDialog = (dialogId) => {
    try {
      logger.info(`${LOGGER_PREFIX}::hideDialog`, dialogId);
      store.dispatch(hideDialog(dialogId));
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::hideDialog`, err);
    }
  };
}

export default new DialogService();
