import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import _ from 'lodash';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown';
import { faArrowUpWideShort } from '@fortawesome/pro-solid-svg-icons/faArrowUpWideShort';
import { faArrowDownWideShort } from '@fortawesome/pro-solid-svg-icons/faArrowDownWideShort';

import BongoFontAwesomeIcon from 'common/components/icon/BongoFontAwesomeIcon';
import { SORT_ASCENDING } from 'common/constants/general';

import styles from './SortField.module.scss';

const SortMenuItem = (props) => {
  const {
    item: { label, value },
    onClick,
  } = props;
  return (
    <MenuItem
      value={value}
      onClick={() => {
        onClick(value);
      }}
    >
      {label}
    </MenuItem>
  );
};

SortMenuItem.propTypes = {
  item: PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.string.isRequired }),
  onClick: PropTypes.func.isRequired,
};

const SortField = (props) => {
  const { items, onSortDirectionToggle, onSortFieldChange, sortDirection, value } = props;
  const [sortAnchorEl, setSortAnchorEl] = useState(null);

  const handleSortMenuOpen = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleSortMenuClose = () => {
    setSortAnchorEl(null);
  };

  const getValueLabel = () => {
    return _.find(items, { value }).label;
  };

  return (
    <div className={styles.container}>
      <ButtonGroup className={styles.sortButton} variant='outlined' aria-label={t`sort options`}>
        <Button
          aria-label={t`toggle sort direction`}
          aria-sort={sortDirection === SORT_ASCENDING ? t`ascending` : t`descending`}
          className={styles.sortDirection}
          color='inherit'
          onClick={onSortDirectionToggle}
        >
          <BongoFontAwesomeIcon
            icon={sortDirection === SORT_ASCENDING ? faArrowDownWideShort : faArrowUpWideShort}
            size={20}
          />
        </Button>
        <Button
          id={'sort-options'}
          className={styles.sortField}
          color='inherit'
          aria-label={t`sort field`}
          aria-controls={sortAnchorEl ? 'sort-options-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={sortAnchorEl ? 'true' : undefined}
          onClick={handleSortMenuOpen}
          endIcon={<BongoFontAwesomeIcon icon={faAngleDown} size={12} />}
        >
          {getValueLabel()}
        </Button>
      </ButtonGroup>

      <Menu
        id={'sort-options-menu'}
        anchorEl={sortAnchorEl}
        open={Boolean(sortAnchorEl)}
        onClose={handleSortMenuClose}
        MenuListProps={{
          'aria-labelledby': 'sort-options',
        }}
      >
        {items.map((item) => {
          if (item.visible === false) {
            return null;
          }
          return (
            <SortMenuItem
              key={item.value}
              item={item}
              onClick={(value) => {
                onSortFieldChange(value);
                handleSortMenuClose();
              }}
            />
          );
        })}
      </Menu>
    </div>
  );
};

SortField.propTypes = {
  onSortDirectionToggle: PropTypes.func.isRequired,
  onSortFieldChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.string.isRequired }))
    .isRequired,
  sortDirection: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

SortField.defaultProps = {
  sortDirection: SORT_ASCENDING,
};

export default SortField;
