import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import _ from 'lodash';

import { ACTIVITY } from 'common/dattrs';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { faEllipsisH } from '@fortawesome/pro-duotone-svg-icons/faEllipsisH';
import { faDownload } from '@fortawesome/pro-regular-svg-icons/faDownload';
import { faCog } from '@fortawesome/pro-regular-svg-icons/faCog';
import { faLinkSimple } from '@fortawesome/pro-regular-svg-icons/faLinkSimple';
import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-duotone-svg-icons/faEyeSlash';
import { faCopy } from '@fortawesome/pro-regular-svg-icons/faCopy';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import { faRocket } from '@fortawesome/pro-regular-svg-icons/faRocket';

import ActivityCopyDialog from 'components/activity/ActivityCopyDialog';
import ActivityDeleteDialog from 'components/activity/ActivityDeleteDialog';
import ActivitySettingsDialog from 'components/activity/ActivitySettingsDialog';
import BongoFontAwesomeIcon from 'common/components/icon/BongoFontAwesomeIcon';
import CourseService from 'service/course';
import LoadingIconButton from 'common/components/button/LoadingIconButton';
import MenuItem from 'common/components/menu/MenuItem';
import NotificationService, { NOTIFICATION_TYPES } from 'service/notification';
import Permissions from 'common/utils/permissions';
import Tooltip from 'common/components/tooltip/Tooltip';
import { COPY_STATES } from 'common/constants/copyStates';
import { copyAsyncResultsToClipboard } from 'common/utils/domUtils';
import { getCurrentOrg } from 'redux/org/org.selectors';

import styles from './ActivityListItemActions.module.scss';

function ActivityListItemActions(props) {
  const { activity, courseRole, onLaunchAsLearner, showVisibilityConfirm, toggleVisibility, visibilityToggleLoading } = props;
  const {
    id: activityId,
    name: assignmentName,
    resourceLinkId,
    states: { visible },
    copyInfo,
  } = activity;

  const [anchorEl, setAnchorEl] = useState(null);
  const [bongoDialogVisible, setBongoDialogVisible] = useState(false);
  const [generatingLink, setGeneratingLink] = useState(false);
  const [downloadingSCORM, setDownloadingSCORM] = useState(false);
  const [copyDialogVisible, setCopyDialogVisible] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [inErrorState, setInErrorState] = useState(true);

  const org = useSelector(getCurrentOrg);

  React.useLayoutEffect(() => {
    setInErrorState([COPY_STATES.FAILED].includes(copyInfo?.state));
  }, [copyInfo]);

  function handleMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  async function onAssignmentLinkCopy() {
    setGeneratingLink(true);
    try {
      await copyAsyncResultsToClipboard(
        CourseService.generateLink({ courseId: activity.courseId, activityId: activity.id })
      );
      NotificationService.notify({ message: t`Assignment link copied` });
    } catch (e) {
      NotificationService.notify({ message: t`Could not copy assignment link`, severity: NOTIFICATION_TYPES.ERROR });
    }
    setGeneratingLink(false);
    handleMenuClose();
  }

  async function onDownloadSCORM() {
    setDownloadingSCORM(true);
    const result = await CourseService.generateLink({ courseId: activity.courseId, activityId: activity.id });
    const scorm = await CourseService.downloadSCORMPackage({
      courseId: activity.courseId,
      activityId: activity.id,
      url: result,
    });
    window.open(scorm, '_self');
    NotificationService.notify({ message: t`SCORM package download started` });
    setDownloadingSCORM(false);
    handleMenuClose();
  }

  function getOverflowMenu() {
    const groupOneActions = [];
    const groupTwoActions = [];
    const groupThreeActions = [];
    const groupFourActions = [];
    const groupFiveActions = [];

    if (Permissions.isActivityCopyVisible(courseRole)) {
      groupOneActions.push(
        <MenuItem
          {...ACTIVITY.list.item.copy}
          disabled={inErrorState}
          key={`copyAssignmentMenuItem[${activityId}]`}
          label={t`Make a copy`}
          icon={faCopy}
          onClick={() => {
            setCopyDialogVisible(true);
            handleMenuClose();
          }}
        />
      );
    }

    if (Permissions.isActivityLaunchAsLearnerVisible()) {
      groupTwoActions.push(
        <MenuItem
          {...ACTIVITY.list.item.copy}
          disabled={inErrorState}
          key={`launchAsLearner[${activityId}]`}
          label={t`Launch as learner`}
          icon={faRocket}
          onClick={onLaunchAsLearner}
        />
      );
    }

    if (Permissions.isActivityCopyLinkVisible(courseRole)) {
      groupThreeActions.push(
        <MenuItem
          {...ACTIVITY.list.item.copyLink}
          disabled={generatingLink || inErrorState}
          key={`copyAssignmentLinkMenuItem[${activityId}]`}
          loading={generatingLink}
          onClick={onAssignmentLinkCopy}
          label={t`Copy assignment link`}
          icon={faLinkSimple}
        />
      );
    }

    if (Permissions.isActivityDownloadSCORMVisible(courseRole)) {
      groupThreeActions.push(
        <MenuItem
          {...ACTIVITY.list.item.downloadSCORM}
          disabled={downloadingSCORM || inErrorState}
          key={`downloadSCORMMenuItem[${activityId}]`}
          loading={downloadingSCORM}
          onClick={onDownloadSCORM}
          label={t`Download SCORM package`}
          icon={faDownload}
        />
      );
    }

    if (Permissions.isActivityDeleteVisible(courseRole) && inErrorState) {
      groupFourActions.push(
        <MenuItem
          {...ACTIVITY.list.item.delete}
          label={t`Delete`}
          key={`deleteAssignmentMenuItem[${activityId}]`}
          icon={faTrash}
          onClick={() => {
            setDeleteDialogVisible(true);
            handleMenuClose();
          }}
        />
      );
    }

    if (Permissions.isActivitySettingsVisible(courseRole)) {
      groupFiveActions.push(
        <MenuItem
          {...ACTIVITY.list.item.configure}
          disabled={inErrorState}
          key={`settingsMenuItem[${activityId}]`}
          label={t`Settings`}
          icon={faCog}
          onClick={() => {
            setBongoDialogVisible(true);
            handleMenuClose();
          }}
        />
      );
    }

    if (
      groupOneActions.length &&
      (groupTwoActions.length || groupThreeActions.length || groupFourActions.length || groupFiveActions.length)
    ) {
      groupOneActions.push(<Divider key={`groupOneDivider[${activityId}]`} />);
    }

    if (groupTwoActions.length && (groupThreeActions.length || groupFourActions.length || groupFiveActions.length)) {
      groupTwoActions.push(<Divider key={`groupTwoDivider[${activityId}]`} />);
    }

    if (groupThreeActions.length && (groupFourActions.length || groupFiveActions.length)) {
      groupThreeActions.push(<Divider key={`groupThreeDivider[${activityId}]`} />);
    }

    if (groupFourActions.length && groupFiveActions.length) {
      groupFourActions.push(<Divider key={`groupFoutDivider[${activityId}]`} />);
    }

    const menuActions = [
      ...groupOneActions,
      ...groupTwoActions,
      ...groupThreeActions,
      ...groupFourActions,
      ...groupFiveActions,
    ];
    if (_.isEmpty(menuActions)) {
      return null;
    }

    return (
      <>
        <div className={styles.overflowContainer}>
          <IconButton
            {...ACTIVITY.list.item.menu}
            id={`activity-options[${activity.id}]`}
            aria-label={t`${assignmentName} options`}
            aria-controls={anchorEl ? `activity-options-menu[${activity.id}]` : undefined}
            aria-haspopup='true'
            aria-expanded={anchorEl ? 'true' : undefined}
            className={styles.actionsButton}
            onClick={handleMenuOpen}
            color='primary'
          >
            <BongoFontAwesomeIcon icon={faEllipsisH} size={22} />
          </IconButton>
        </div>
        <Menu
          id={`activity-options-menu[${activity.id}]`}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          MenuListProps={{
            'aria-labelledby': `activity-options[${activity.id}]`,
          }}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {[...menuActions]}
        </Menu>
      </>
    );
  }

  if (showVisibilityConfirm) {
    return null;
  }

  return (
    <div className={styles.actionsContainer}>
      {Permissions.isActivityVisibilityVisible(courseRole) && (
        <div className={styles.visibilityContainer}>
          <Tooltip title={visible ? t`Assignment is visible to learners` : t`Assignment is hidden from learners`}>
            <span>
              <LoadingIconButton
                {...ACTIVITY.list.item.visibility}
                id={`activity-visibility[${activity.id}]`}
                disabled={inErrorState}
                aria-label={visible ? 'Toggle assignment hidden' : 'Toggle assignment visible'}
                className={cx(styles.actionsButton, styles.visibleBtn, inErrorState && styles.inErrorState)}
                loading={visibilityToggleLoading}
                onClick={toggleVisibility}
                color='neutral'
              >
                {visible ? (
                  <BongoFontAwesomeIcon icon={faEye} size={22} />
                ) : (
                  <BongoFontAwesomeIcon icon={faEyeSlash} color={'black'} size={22} />
                )}
              </LoadingIconButton>
            </span>
          </Tooltip>
        </div>
      )}

      {getOverflowMenu()}

      {bongoDialogVisible && (
        <ActivitySettingsDialog
          activityId={activityId}
          resourceLinkId={resourceLinkId}
          onHide={() => {
            setBongoDialogVisible(false);
          }}
        />
      )}

      {copyDialogVisible && (
        <ActivityCopyDialog
          activity={activity}
          orgId={org.id}
          onHide={() => {
            setCopyDialogVisible(false);
          }}
          onCopyComplete={() => {
            setCopyDialogVisible(false);
          }}
        />
      )}

      {deleteDialogVisible && (
        <ActivityDeleteDialog
          activity={activity}
          onHide={() => {
            setDeleteDialogVisible(false);
          }}
        />
      )}
    </div>
  );
}

ActivityListItemActions.propTypes = {
  activity: PropTypes.object.isRequired,
  courseRole: PropTypes.string,
  onLaunchAsLearner: PropTypes.func,
  showVisibilityConfirm: PropTypes.bool.isRequired,
  toggleVisibility: PropTypes.func.isRequired,
  visibilityToggleLoading: PropTypes.bool,
};

ActivityListItemActions.defaultProps = {
  courseRole: null,
  onLaunchAsLearner: () => {},
  visibilityToggleLoading: false,
};

export default memo(ActivityListItemActions);
